import { createSelector, Selector } from 'reselect';

import type { SerializedApiError } from '@advitam/api';
import { FullClient, FullClientJSON } from '@advitam/api/models/Client/Full';
import {
  DeathDeclarationJSON,
  DeathDeclarationState,
} from '@advitam/api/models/DeathDeclaration';

import { CLIENT } from './constants';
import type { AppStateSubset, State } from './slice';

type ClientSelectors<T> = Selector<AppStateSubset, T>;

function selectClientDomain(state: AppStateSubset): State {
  return state[CLIENT];
}

export function makeSelectRawClient(): ClientSelectors<FullClientJSON | null> {
  return createSelector(selectClientDomain, ({ client }) => client);
}

export function makeSelectClient(): ClientSelectors<FullClient | null> {
  return createSelector(makeSelectRawClient(), rawClient =>
    rawClient?.id ? new FullClient(rawClient) : null,
  );
}

export function makeSelectDeathDeclarations(): ClientSelectors<
  DeathDeclarationJSON[]
> {
  return createSelector(selectClientDomain, state => state.deathDeclarations);
}

export function makeSelectPendingDeathDeclarations(): ClientSelectors<
  DeathDeclarationJSON[]
> {
  return createSelector(makeSelectDeathDeclarations(), declarations =>
    declarations.filter(
      declaration => declaration.state === DeathDeclarationState.PENDING,
    ),
  );
}

export function makeSelectIsLoading(): ClientSelectors<boolean> {
  return createSelector(selectClientDomain, ({ isLoading }) => isLoading);
}

export function makeSelectIsSaving(): ClientSelectors<boolean> {
  return createSelector(selectClientDomain, ({ isSaving }) => isSaving);
}

export function makeSelectError(): ClientSelectors<SerializedApiError | null> {
  return createSelector(selectClientDomain, ({ error }) => error);
}
