import { BillingType } from '@advitam/api/models/BillingType';
import type { NewCrematorium } from './types';

export const CREMATORIUM = 'Crematorium';

export const Path = {
  AGES: 'ages',
  CEREMONIES: 'ceremonies',
  MISCELLANEOUS: 'divers',
  GENERAL: '',
  ROOMS: 'salles',
  WORSHIP: 'culte',
};

export const CREMATORIUM_SKELETON: NewCrematorium = {
  name: '',
  disabled: false,
  address: null,
  address_l2: null,
  postal_code: null,
  insee_code: null,
  city: null,
  department: null,
  country: null,
  latitude: null,
  longitude: null,
  manual_address: false,
  phone: null,
  email: null,
  prefered_contact_media: null,
  opening_hours: null,
  fax: null,
  phone_2: null,
  phone_3: null,
  email_2: null,
  email_3: null,
  email_4: null,
  comment: null,
  current_update_user_id: null,
  last_update_user_id: null,
  billing_type: BillingType.DEAL,
  ceremony: null,
  exhumation_lt_5_price: null,
  exhumation_gt_5_price: null,
  scellement_urn_fee: null,
  dispersion_jds_fee: null,
  length_limit: null,
  width_limit: null,
  height_limit: null,
  cheque_order: null,
  cheque_address: null,
  urn_returning_schedule: null,
  catholic_price: null,
  protestant_price: null,
  family_has_to_contact: null,
  conviviality_room: null,
  conviviality_room_cost: null,
  urn_conservation_free_period: null,
  urn_conservation_fee: null,
  is_live_default: null,
  rib: null,
  iban: null,
  vat: null,
  checkout_type: null,
  provides_ceremony_master: null,
  reduced_price_cityhalls: [],
  authentic_document_needed: null,
  siret: null,
  headquarter_name: null,
  headquarter_address: null,
  headquarter_postal_code: null,
  headquarter_country: null,
  headquarter_city: null,
  website: null,
  vat_rate: 20,
  worships: [],
  current_update_dt: null,
  last_update_dt: null,
};
