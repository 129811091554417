import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { FormLayout, NumberInput, Select } from '@advitam/ui';
import { Layout, messages as crudMessages } from 'containers/Crud';
import actionMessages from 'messages/actions';

import messages from './messages';
import { makeSelectStaySelectItems } from './selectors';

export default function Contents(): JSX.Element {
  const intl = useIntl();

  const stays = useSelector(makeSelectStaySelectItems());
  const boolSelectItems = [
    { value: true, name: intl.formatMessage(actionMessages.yes) },
    { value: false, name: intl.formatMessage(actionMessages.no) },
  ];

  return (
    <Layout.Container>
      <Layout.ColumnGroup>
        <Layout.Column>
          <Layout.Fieldset
            title={<FormattedMessage id={messages.functioning.id} />}
          >
            <FormLayout.Row>
              <Select
                label={<FormattedMessage id={messages.defaultStay.id} />}
                tooltip={
                  <FormattedMessage id={messages.defaultStayTooltip.id} />
                }
                name="funeralParlor.default_stay_id"
                items={stays}
                unselectable
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <Select
                label={
                  <FormattedMessage id={messages.acceptsCoffinDelivery.id} />
                }
                tooltip={
                  <FormattedMessage
                    id={messages.acceptsCoffinDeliveryTooltip.id}
                  />
                }
                name="funeralParlor.accept_coffin_delivery"
                items={boolSelectItems}
                unselectable
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <Select
                label={<FormattedMessage id={messages.depository.id} />}
                tooltip={
                  <FormattedMessage id={messages.depositoryTooltip.id} />
                }
                name="funeralParlor.depository"
                items={boolSelectItems}
              />
            </FormLayout.Row>
          </Layout.Fieldset>
        </Layout.Column>

        <Layout.Column>
          <Layout.Fieldset
            title={<FormattedMessage id={messages.billing.id} />}
            tooltip={<FormattedMessage id={messages.billingTooltip.id} />}
          >
            <FormLayout.Row>
              <NumberInput
                label={<FormattedMessage id={messages.admissionPrice.id} />}
                tooltip={
                  <FormattedMessage id={messages.admissionPriceTooltip.id} />
                }
                name="funeralParlor.admission_price"
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <NumberInput
                label={
                  <FormattedMessage
                    id={messages.admissionOutsideOpeningHoursPrice.id}
                  />
                }
                tooltip={
                  <FormattedMessage
                    id={messages.admissionOutsideOpeningHoursPriceTooltip.id}
                  />
                }
                name="funeralParlor.admission_outside_opening_hours_price"
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <NumberInput
                label={<FormattedMessage id={messages.cleaningLabPrice.id} />}
                tooltip={
                  <FormattedMessage id={messages.cleaningLabPriceTooltip.id} />
                }
                name="funeralParlor.cleaning_lab_price"
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <NumberInput
                label={
                  <FormattedMessage id={messages.ritualCleaningLabPrice.id} />
                }
                tooltip={
                  <FormattedMessage
                    id={messages.ritualCleaningLabPriceTooltip.id}
                  />
                }
                name="funeralParlor.ritual_cleaning_lab_price"
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <NumberInput
                label={
                  <FormattedMessage
                    id={messages.embalmingCleaningLabPrice.id}
                  />
                }
                tooltip={
                  <FormattedMessage
                    id={messages.embalmingCleaningLabPriceTooltip.id}
                  />
                }
                name="funeralParlor.embalming_lab_price"
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <NumberInput
                label={<FormattedMessage id={messages.ceremonyRoom.id} />}
                tooltip={
                  <FormattedMessage id={messages.ceremonyRoomTooltip.id} />
                }
                name="funeralParlor.ceremony_room"
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
              <NumberInput
                label={<FormattedMessage id={messages.coolerSetupPrice.id} />}
                tooltip={
                  <FormattedMessage id={messages.coolerSetupPriceTooltip.id} />
                }
                name="funeralParlor.cooler_setup_price"
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <NumberInput
                label={<FormattedMessage id={messages.departureRoomPrice.id} />}
                tooltip={
                  <FormattedMessage
                    id={messages.departureRoomPriceTooltip.id}
                  />
                }
                name="funeralParlor.departure_room_price"
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
              <NumberInput
                label={<FormattedMessage id={messages.casketingPrice.id} />}
                tooltip={
                  <FormattedMessage id={messages.casketingPriceTooltip.id} />
                }
                name="funeralParlor.casketing_price"
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <Select
                label={<FormattedMessage id={messages.vatRate.id} />}
                tooltip={<FormattedMessage id={messages.vatRateTooltip.id} />}
                name="funeralParlor.vat_rate"
                items={[
                  { value: 0, name: intl.formatMessage(messages.vatRate0) },
                  {
                    value: 5.5,
                    name: intl.formatMessage(messages.vatRate5dot5),
                  },
                  { value: 10, name: intl.formatMessage(messages.vatRate10) },
                  { value: 20, name: intl.formatMessage(messages.vatRate20) },
                ]}
              />
            </FormLayout.Row>
          </Layout.Fieldset>
        </Layout.Column>
      </Layout.ColumnGroup>
    </Layout.Container>
  );
}
