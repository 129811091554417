import { useMemo, ReactNode } from 'react'
import { useIntl } from 'react-intl'

import Select from '../Select'
import type { SelectableItem } from '../UI/Select'

import messages from './messages'

enum Civility {
  MR = 'mr',
  MRS = 'mrs',
}

interface CivilityInputProps {
  name: string
  label?: ReactNode
  required?: boolean
  placeholder?: ReactNode
  disabled?: boolean
}

export default function CivilityInput({
  name,
  label,
  required,
  placeholder,
  disabled,
}: CivilityInputProps): JSX.Element {
  const intl = useIntl()
  const civilityOptions: SelectableItem<Civility>[] = useMemo(
    () => [
      { name: intl.formatMessage(messages.mr), value: Civility.MR },
      { name: intl.formatMessage(messages.mrs), value: Civility.MRS },
    ],
    [intl],
  )

  return (
    <Select
      required={required}
      unselectable={!required}
      name={name}
      label={label}
      items={civilityOptions}
      placeholder={placeholder}
      disabled={disabled}
    />
  )
}
