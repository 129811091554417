import { ReactNode, useCallback, useMemo } from 'react'
import { Field, useForm } from 'react-final-form'

import { useFormValues } from '@advitam/react'

import GooglePlaceUI from '../UI/GooglePlace'
import { Address as AddressUI } from '../UI/GooglePlace/types'
import { isRequired, useValidators } from '../validators'
import { serialize } from './serializers'
import HiddenInput from '../Hidden'

interface GooglePlaceProps {
  placeholder?: string
  required?: boolean
  disabled?: boolean
  label?: ReactNode
  name?: string
  prefix?: string
  formatDisplayValue?: (address: AddressUI) => string
  onlyFrance?: boolean
  onlyCities?: boolean
}

/** Like GooglePlace but with a country object */
export default function GooglePlace({
  name,
  prefix = '',
  label,
  required,
  disabled,
  placeholder,
  formatDisplayValue,
  onlyCities,
  onlyFrance,
}: GooglePlaceProps): JSX.Element {
  const fullPrefix = name ? [name, prefix].join('.') : ''
  const form = useForm()

  const values = useFormValues<string | number | null>([
    `${fullPrefix}address`,
    `${fullPrefix}postal_code`,
    `${fullPrefix}city`,
    `${fullPrefix}country.name`,
    `${fullPrefix}country.code`,
    `${fullPrefix}latitude`,
    `${fullPrefix}longitude`,
  ])

  const value = useMemo(
    (): AddressUI =>
      serialize({
        address: values[0] as string | null,
        postal_code: values[1] as string | null,
        city: values[2] as string | null,
        country: {
          name: values[3] as string | null,
          code: values[4] as string | null,
        },
        latitude: values[5] as number | null,
        longitude: values[6] as number | null,
      }),
    [values],
  )

  const onChange = useCallback(
    (r: AddressUI): void => {
      form.batch(() => {
        form.change(
          `${fullPrefix}address`,
          [r.street_number || '', r.street_name || ''].join(' ').trim() || null,
        )
        form.change(`${fullPrefix}postal_code`, r.postal_code)
        form.change(`${fullPrefix}insee_code`, null)
        form.change(`${fullPrefix}city`, r.city)
        form.change(`${fullPrefix}department`, null)
        form.change(`${fullPrefix}latitude`, r.latitude)
        form.change(`${fullPrefix}longitude`, r.longitude)
        form.change(`${fullPrefix}country.name`, r.country)
        form.change(`${fullPrefix}country.code`, r.country_code)
      })
    },
    [form, fullPrefix],
  )

  const validators = useValidators(required && isRequired)

  return (
    <>
      <Field<string>
        name={onlyCities ? `${fullPrefix}city` : `${fullPrefix}address`}
        validate={validators}
      >
        {({ input, meta }): JSX.Element => (
          <>
            <GooglePlaceUI
              label={label}
              value={value}
              onChange={onChange}
              error={meta.touched && !meta.valid}
              placeholder={placeholder}
              disabled={disabled}
              formatDisplayValue={formatDisplayValue}
              onlyCities={onlyCities}
              onlyFrance={onlyFrance}
            />

            <input type="hidden" name={input.name} value={input.value} />
          </>
        )}
      </Field>
      <HiddenInput name={onlyCities ? `${fullPrefix}address` : `${fullPrefix}city`} />
      <HiddenInput name={`${fullPrefix}country.name`} />
      <HiddenInput name={`${fullPrefix}country.code`} />
      <HiddenInput name={`${fullPrefix}postal_code`} />
      <HiddenInput name={`${fullPrefix}insee_code`} />
      <HiddenInput name={`${fullPrefix}department`} />
      <HiddenInput name={`${fullPrefix}latitude`} />
      <HiddenInput name={`${fullPrefix}longitude`} />
    </>
  )
}
