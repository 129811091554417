import { authenticate } from '../../lib/decorators'
import { get } from '../../lib/methods'
import { ApiRequestDescriptor } from '../../lib/request'
import { CompanyJSON } from '../../models/Company'
import { Formalities } from './Formalities'

export const Companies = {
  Formalities,

  show: (id: string): ApiRequestDescriptor<CompanyJSON> =>
    authenticate(get(`/api/v1/companies/${id}`)),
}
