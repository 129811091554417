import { authenticate } from '../../lib/decorators'
import type { ApiRequestDescriptor } from '../../lib/request'
import { get, post, put } from '../../lib/methods'
import type { FullClientJSON } from '../../models/Client/Full'
import type { ClientJSON } from '../../models/Client'

import { serialize } from './serializers'
import { Abilities } from './Abilities'
import { Companies } from './Companies'
import { Deceased } from './Deceased'
import { OptOut } from './OptOut'
import { SupportingDocuments } from './SupportingDocuments'

interface ClientsFilters {
  q?: string
  companies_id_in?: number[]
  defunct_id_not_null?: boolean
  page?: number
  per_page?: number
}

export const Clients = {
  Abilities,
  Companies,
  Deceased,
  OptOut,
  SupportingDocuments,

  index: (filters?: ClientsFilters): ApiRequestDescriptor<ClientJSON[]> =>
    authenticate(get('/api/v1/clients', filters)),

  show: (id: number): ApiRequestDescriptor<FullClientJSON> =>
    authenticate(get(`/api/v1/clients/${id}`)),

  create: (
    client: Omit<FullClientJSON | ClientJSON, 'id' | 'inserted_at' | 'updated_at'>,
  ): ApiRequestDescriptor<ClientJSON> => authenticate(post('/api/v1/clients', serialize(client))),

  update: (client: Partial<FullClientJSON | ClientJSON>): ApiRequestDescriptor<FullClientJSON> =>
    authenticate(put(`/api/v1/clients/${client.id}`, serialize(client))),
}
