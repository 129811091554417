import { sleep } from './sleep'

function always(_error: unknown): boolean {
  return true
}

type IsRetryable = (error: unknown) => boolean

const BASE_INTERVAL = 500
const MULTIPLIER = 2
const MAX_RETRIES = 5

interface Config {
  maxRetries?: number
  baseInterval?: number
  multiplier?: number
}

/**
 * Retry a function with an exponential backoff until it succeeds or the maximum number of retries
 * is reached.
 *
 * @param fn - The function to retry
 * @param isRetryable - A function that determines if the error is retryable. Default: always.
 * @param config - The configuration object
 * @returns The result of the function
 */
export async function retryable<T>(
  fn: () => T | Promise<T>,
  isRetryable: IsRetryable = always,
  config: Config = {},
): Promise<T> {
  const maxRetries = config.maxRetries ?? MAX_RETRIES
  const baseInterval = config.baseInterval ?? BASE_INTERVAL
  const multiplier = config.multiplier ?? MULTIPLIER

  let n = 0

  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      // eslint-disable-next-line no-await-in-loop
      return await fn()
    } catch (error) {
      n += 1

      if (!isRetryable(error) || n >= maxRetries) {
        throw error
      }

      // eslint-disable-next-line no-await-in-loop
      await sleep(baseInterval * multiplier ** n)
    }
  }
}
