import { FormattedApiError as BaseFormattedApiError } from '@advitam/ui';
import type { ApiErrorShape } from '@advitam/ui/components/Format/ApiError';

import messages from './messages';

interface FormattedApiErrorProps {
  error: ApiErrorShape;
}

export default function FormattedApiError({
  error,
}: FormattedApiErrorProps): JSX.Element {
  return <BaseFormattedApiError error={error} messages={messages} />;
}
