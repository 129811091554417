import { FormattedMessage, useIntl } from 'react-intl';
import { Field, FormSpy, useForm, useFormState } from 'react-final-form';

import { FormLayout, NumberInput, Select } from '@advitam/ui';
import { Layout, messages as crudMessages } from 'containers/Crud';
import actionMessages from 'messages/actions';

import messages from './messages';
import AgreementLocation from './AgreementLocation';
import type { HospitalForm } from '../types';

export default function Contents(): JSX.Element {
  const intl = useIntl();
  const form = useForm<HospitalForm>();
  const { values } = useFormState<HospitalForm>();

  const boolSelectItems = [
    { value: true, name: intl.formatMessage(actionMessages.yes) },
    { value: false, name: intl.formatMessage(actionMessages.no) },
  ];

  return (
    <Layout.Container>
      <Layout.ColumnGroup>
        <Layout.Column>
          <Layout.Fieldset
            title={<FormattedMessage id={messages.functioning.id} />}
          >
            <FormLayout.Row>
              <Select
                label={<FormattedMessage id={messages.cleaning.id} />}
                tooltip={<FormattedMessage id={messages.cleaningTooltip.id} />}
                name="hospital.cleaning"
                items={boolSelectItems}
                unselectable
              />
              <Select
                label={<FormattedMessage id={messages.casketing.id} />}
                tooltip={<FormattedMessage id={messages.casketingTooltip.id} />}
                name="hospital.casketing"
                items={boolSelectItems}
                unselectable
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <Select
                label={<FormattedMessage id={messages.supplyShroud.id} />}
                tooltip={
                  <FormattedMessage id={messages.supplyShroudTooltip.id} />
                }
                name="hospital.supply_shroud"
                items={boolSelectItems}
                unselectable
              />
              <Select
                label={<FormattedMessage id={messages.coffinDelivery.id} />}
                tooltip={
                  <FormattedMessage id={messages.coffinDeliveryTooltip.id} />
                }
                name="hospital.coffin_delivery"
                items={boolSelectItems}
                unselectable
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <Select
                label={<FormattedMessage id={messages.paperHold.id} />}
                tooltip={<FormattedMessage id={messages.paperHoldTooltip.id} />}
                name="hospital.paper_hold"
                items={boolSelectItems}
                unselectable
              />
              <NumberInput
                label={<FormattedMessage id={messages.nbHolders.id} />}
                tooltip={<FormattedMessage id={messages.nbHoldersTooltip.id} />}
                name="hospital.nb_holders"
                suffix={<FormattedMessage id={crudMessages.unitPerson.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <Select
                label={<FormattedMessage id={messages.mortuary.id} />}
                tooltip={<FormattedMessage id={messages.mortuaryTooltip.id} />}
                name="hospital.mortuary"
                items={boolSelectItems}
                unselectable
              />
            </FormLayout.Row>
            <FormSpy subscription={{ values: true }}>
              {(): null => {
                const {
                  mortuary,
                  agreement_location: agreementLocation,
                } = values.hospital;
                if (mortuary !== false && agreementLocation?.type) {
                  form.change('hospital.agreement_location', null);
                }
                return null;
              }}
            </FormSpy>
            <Field name="hospital.agreement_location">
              {(): JSX.Element => <AgreementLocation />}
            </Field>
          </Layout.Fieldset>
        </Layout.Column>

        <Layout.Column>
          <Layout.Fieldset
            title={<FormattedMessage id={messages.billing.id} />}
            tooltip={<FormattedMessage id={messages.billingTooltip.id} />}
          >
            <FormLayout.Row>
              <NumberInput
                label={<FormattedMessage id={messages.casketingFee.id} />}
                tooltip={
                  <FormattedMessage id={messages.casketingFeeTooltip.id} />
                }
                name="hospital.casketing_fee"
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
              <NumberInput
                label={<FormattedMessage id={messages.labFee.id} />}
                tooltip={<FormattedMessage id={messages.labFeeTooltip.id} />}
                name="hospital.lab_fee"
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <NumberInput
                label={<FormattedMessage id={messages.baseFee.id} />}
                tooltip={
                  <FormattedMessage
                    id={messages.baseFeeTooltip.id}
                    values={{ br: <br /> }}
                  />
                }
                name="hospital.base_fee"
                step={0.01}
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <NumberInput
                label={<FormattedMessage id={messages.baseDays.id} />}
                tooltip={
                  <FormattedMessage
                    id={messages.baseDaysTooltip.id}
                    values={{ br: <br /> }}
                  />
                }
                name="hospital.base_days"
                suffix={<FormattedMessage id={crudMessages.unitDay.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <NumberInput
                label={<FormattedMessage id={messages.costPerDay.id} />}
                tooltip={
                  <FormattedMessage id={messages.costPerDayTooltip.id} />
                }
                name="hospital.cost_per_day"
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <Select
                label={<FormattedMessage id={messages.vatRate.id} />}
                tooltip={<FormattedMessage id={messages.vatRateTooltip.id} />}
                name="hospital.tva_rate"
                items={[
                  { value: 0, name: intl.formatMessage(messages.vatRate0) },
                  {
                    value: 5.5,
                    name: intl.formatMessage(messages.vatRate5dot5),
                  },
                  { value: 10, name: intl.formatMessage(messages.vatRate10) },
                  { value: 20, name: intl.formatMessage(messages.vatRate20) },
                ]}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <NumberInput
                label={<FormattedMessage id={messages.baseFeeFamily.id} />}
                tooltip={
                  <FormattedMessage id={messages.baseFeeFamilyTooltip.id} />
                }
                name="hospital.base_fee_family"
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <NumberInput
                label={<FormattedMessage id={messages.baseDaysFamily.id} />}
                tooltip={
                  <FormattedMessage
                    id={messages.baseDaysFamilyTooltip.id}
                    values={{ br: <br /> }}
                  />
                }
                name="hospital.base_days_family"
                suffix={<FormattedMessage id={crudMessages.unitDay.id} />}
              />
            </FormLayout.Row>
            <FormLayout.Row>
              <NumberInput
                label={<FormattedMessage id={messages.costPerDayFamily.id} />}
                tooltip={
                  <FormattedMessage id={messages.costPerDayFamilyTooltip.id} />
                }
                name="hospital.cost_per_day_family"
                suffix={<FormattedMessage id={crudMessages.unitCurrency.id} />}
              />
            </FormLayout.Row>
          </Layout.Fieldset>
        </Layout.Column>
      </Layout.ColumnGroup>
    </Layout.Container>
  );
}
