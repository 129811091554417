import { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, useFormState } from 'react-final-form';

import { Button, ResourceList } from '@advitam/ui';
import type { Filter } from '@advitam/ui/components/ResourceList';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';

import actionMessages from 'messages/actions';

import { makeSelectCanEdit } from '../../../../selectors';
import messages from '../messages';
import { makeSelectCategoryOptions, makeSelectFilters } from '../selectors';
import { setFilters } from '../slice';
import type { WarehouseProductsForm, ProductsFilters } from '../types';
import {
  getAvailableColorOptions,
  getAvailableMaterialOptions,
  getAvailableSizeOptions,
  getAvailableWorshipTagsOptions,
} from './utils';
import style from './List.module.scss';
import { PRODUCT_SKELETON, SPECIFIC_FILTER_MAP } from '../constants';

export default function ProductsFilters(): JSX.Element {
  const intl = useIntl();
  const dispatch = useDispatch();

  const form = useForm<WarehouseProductsForm>();
  const { values } = useFormState<WarehouseProductsForm>();

  const canUserEdit = useSelector(makeSelectCanEdit());
  const filters = useSelector(makeSelectFilters());

  const boolean: SelectableItem<boolean>[] = [
    {
      value: true,
      name: intl.formatMessage(actionMessages.yes),
    },
    {
      value: false,
      name: intl.formatMessage(actionMessages.no),
    },
  ];

  const filtersShape: Filter[] = [
    {
      type: 'Select',
      name: 'category_type_eq',
      placeholder: intl.formatMessage(messages.category),
      items: useSelector(makeSelectCategoryOptions(intl)),
      initialValue: filters.category_type_eq,
    },
  ];

  const specificFilters: Record<string, Filter> = {
    color_eq: {
      type: 'Select',
      name: 'color_eq',
      placeholder: intl.formatMessage(messages.color),
      items: getAvailableColorOptions(values.sectionValues),
      initialValue: filters.color_eq,
    },
    material_eq: {
      type: 'Select',
      name: 'material_eq',
      placeholder: intl.formatMessage(messages.material),
      items: getAvailableMaterialOptions(values.sectionValues),
      initialValue: filters.material_eq,
    },
    hermetic_eq: {
      type: 'Select',
      name: 'hermetic_eq',
      placeholder: intl.formatMessage(messages.hermetic),
      items: boolean,
      initialValue: filters.hermetic_eq,
    },
    worship_tag_eq: {
      type: 'Select',
      name: 'worship_tag_eq',
      placeholder: intl.formatMessage(messages.worship),
      items: getAvailableWorshipTagsOptions(values.sectionValues),
      initialValue: filters.worship_tag_eq,
    },
    size_eq: {
      type: 'Select',
      name: 'size_eq',
      placeholder: intl.formatMessage(messages.size),
      items: getAvailableSizeOptions(values.sectionValues),
      initialValue: filters.size_eq,
    },
    is_default_eq: {
      type: 'Select',
      name: 'is_default_eq',
      placeholder: intl.formatMessage(messages.defaultFunnel),
      items: boolean,
      initialValue: filters.is_default_eq,
    },
  };

  if (filters.category_type_eq) {
    const filterKeys = SPECIFIC_FILTER_MAP[filters.category_type_eq];
    filtersShape.push(...filterKeys.map(filter => specificFilters[filter]));
  }

  const onAddProduct = useCallback((): void => {
    form.change('sectionValues', [PRODUCT_SKELETON, ...values.sectionValues]);
  }, [form, values.sectionValues]);

  const onChange = useCallback(
    (newFilters: ProductsFilters): void => {
      dispatch(setFilters(newFilters));
    },
    [dispatch],
  );

  return (
    <ResourceList.Filters
      className={style.filters}
      filters={filtersShape.filter(filter => filter !== undefined)}
      onChange={onChange}
      searchFilter={{
        name: 'q',
        initialValue: filters.q,
        placeholder: intl.formatMessage(actionMessages.search),
      }}
    >
      {canUserEdit && (
        <Button
          primary
          text={<FormattedMessage id={messages.addProduct.id} />}
          onClick={onAddProduct}
          className={style.add}
        />
      )}
    </ResourceList.Filters>
  );
}
