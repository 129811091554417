import type { ReactNode } from 'react'
import { FormattedMessage, MessageDescriptor } from 'react-intl'

import { FlatButton } from '../../Button'
import defaultMessages from './messages'
import style from './ApiError.module.scss'

function reload(): void {
  window.location.reload()
}

function a(chunk: ReactNode): ReactNode {
  return (
    <FlatButton className={style.link} onClick={reload}>
      {chunk}
    </FlatButton>
  )
}

export interface ApiErrorShape {
  errorCodes: string[]
  status: number
}

interface ApiErrorProps {
  error: ApiErrorShape
  messages?: Record<string, MessageDescriptor>
}

export default function ApiError({ error, messages = {} }: ApiErrorProps): JSX.Element {
  if (error.status === 401) {
    return <FormattedMessage id={defaultMessages.tokens__expired.id} values={{ a }} />
  }

  const code = error.errorCodes[0]
  const message = messages[code] || defaultMessages[code]

  if (message) {
    return <FormattedMessage id={message.id} />
  }

  if (process.env.NODE_ENV === 'production') {
    return <FormattedMessage id={defaultMessages.unhandled.id} />
  }

  return <>{code}</>
}
