/* eslint-disable max-classes-per-file */
import { IntlShape } from 'react-intl';

import { CountryJSON } from '@advitam/api/models/Country';

import { Model } from '../Model';
import { LegacyPerson, LegacyPersonFields } from '../Person';
import { MaritalStatus } from '../Person/MaritalStatus';

export interface DefunctPersonFields extends LegacyPersonFields {
  marital_status: MaritalStatus | null;
  birth_postal_code: string | null;
  birth_insee_code: string | null;
  birth_country: CountryJSON | null;
}

export interface LegacyDefunctPerson extends LegacyPerson<DefunctPersonFields> {
  marital_status: MaritalStatus | null;
  birth_postal_code: string | null;
  birth_insee_code: string | null;
  birth_country: CountryJSON | null;
}

export class LegacyDefunctPerson extends LegacyPerson<DefunctPersonFields> {}

export interface DefunctFields {
  person: DefunctPersonFields;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface Defunct {
  person: LegacyDefunctPerson;
}

export class Defunct<T extends DefunctFields = DefunctFields> extends Model<T> {
  constructor(data: T) {
    super(data);
    this.person = new LegacyDefunctPerson(data.person);
  }

  formattedName(intl?: IntlShape): string {
    return this.person.formattedName(intl);
  }
}

export { StandardDefunctJSON } from './Standard';
