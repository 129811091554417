import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { assert } from '@advitam/support';
import { BorderlessSwitch, FormConverters, TextArea } from '@advitam/ui';
import { AdministrationActions, Fields, Layout } from 'containers/Crud';

import {
  makeSelectDestroyError,
  makeSelectIsDestroying,
  makeSelectRawSupplier,
} from '../../selectors';
import {
  destroySupplier,
  saveSupplier,
  setIsSupplierDisabled,
} from '../../thunk';
import messages from '../../messages';
import style from '../../Supplier.module.scss';
import type { SupplierForm } from '../types';
import Wrapper from '../Wrapper';
import Status from './Status';
import { makeSelectIsSupplierForceDisabled } from './selectors';

const FIELDS_PREFIX = 'supplier';

export default function General(): JSX.Element {
  const dispatch = useDispatch();

  const isDestroying = useSelector(makeSelectIsDestroying());
  const destroyError = useSelector(makeSelectDestroyError());
  const supplier = useSelector(makeSelectRawSupplier());
  assert(supplier !== null);
  const isForcedDisabled = useSelector(makeSelectIsSupplierForceDisabled());

  const onSubmit = useCallback(
    (values: SupplierForm): void => {
      dispatch(saveSupplier(values.supplier));
    },
    [dispatch],
  );

  const onDelete = useCallback(() => {
    dispatch(destroySupplier());
  }, [dispatch]);

  const setIsDisabled = useCallback(
    (value: boolean) => {
      dispatch(setIsSupplierDisabled(value));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        {supplier.id && (
          <AdministrationActions
            isDisabled={supplier.disabled}
            isDisabledDisabled={isForcedDisabled}
            setIsDisabled={setIsDisabled}
            entityName={supplier.name}
            onDelete={onDelete}
            isDeletionLoading={isDestroying}
            destroyError={destroyError}
            className={style.administration_actions}
          >
            <Status />
            <BorderlessSwitch
              label={<FormattedMessage id={messages.negociated.id} />}
              tooltip={<FormattedMessage id={messages.negociatedTooltip.id} />}
              name={`${FIELDS_PREFIX}.negociated`}
              value={FormConverters.BoolCheckbox.value}
              parse={FormConverters.BoolCheckbox.parse}
              format={FormConverters.BoolCheckbox.format}
              className={style.negociated}
            />
          </AdministrationActions>
        )}
        <Layout.ColumnGroup>
          <Layout.Column>
            <Layout.Fieldset
              title={<FormattedMessage id={messages.commentary.id} />}
            >
              <TextArea name={`${FIELDS_PREFIX}.comment`} />
            </Layout.Fieldset>
          </Layout.Column>
        </Layout.ColumnGroup>
        <Layout.ColumnGroup>
          <Layout.Column>
            <Fields.Details
              prefix={FIELDS_PREFIX}
              withShortName
              withRevenue
              requiredSiret
            />
            <Fields.Billing prefix={FIELDS_PREFIX} />
          </Layout.Column>
          <Layout.Column>
            <Fields.Contact
              addressPrefix={FIELDS_PREFIX}
              contactPrefix={FIELDS_PREFIX}
              withoutAuthenticDocument
              withoutPreferredContact
            />
          </Layout.Column>
        </Layout.ColumnGroup>
      </Layout.Container>
    </Wrapper>
  );
}
