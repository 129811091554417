import { useCallback, ReactNode } from 'react';
import { Field, FieldRenderProps } from 'react-final-form';

import { useFormValue } from '@advitam/react';
import { HiddenInput } from '@advitam/ui';

import Api from './Api';
import Google from './Google';
import style from './DefunctBrithPlaceInput.module.scss';

interface DefunctBirthPlaceInputProps {
  label: ReactNode;
}

export default function DefunctBirthPlaceInput({
  label,
}: DefunctBirthPlaceInputProps): JSX.Element {
  const country = useFormValue<string | null>(
    'deal.defunctInfo.person.birth_country.code',
  );
  const isFrance = !country || country === 'FR';

  const children = useCallback(
    ({ meta }: FieldRenderProps<string>) =>
      isFrance ? (
        <Api label={label} error={meta.touched && !meta.valid} />
      ) : (
        <Google label={label} error={meta.touched && !meta.valid} />
      ),
    [isFrance, label],
  );

  return (
    <div className={style.container}>
      <Field name="deal.defunctInfo.person.birthLocation" render={children} />
      <HiddenInput name="deal.defunctInfo.person.birth_country.name" />
      <HiddenInput name="deal.defunctInfo.person.birth_country.code" />
      <HiddenInput name="deal.defunctInfo.person.birth_postal_code" />
      <HiddenInput name="deal.defunctInfo.person.birth_insee_code" />
    </div>
  );
}
