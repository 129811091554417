import { authenticate, withSessionId } from '../../lib/decorators'
import { del, get, post, put } from '../../lib/methods'
import type { ApiRequestDescriptor } from '../../lib/request'
import type { SupplierJSON } from '../../models/Supplier'
import type { SupplierSummaryJSON } from '../../models/Supplier/Summary'
import { Coverage } from './Coverage'
import { Disabled } from './Disabled'
import { Warehouses } from './Warehouses'
import type { SupplierBody } from './types'
import { serialize } from './serializers'

export interface SuppliersIndexFilters {
  q?: string
  department_eq?: string
  negociated_eq?: boolean
  disabled_eq?: boolean
  page?: number
  per_page?: number
  prestation_types_overlaps?: string[]
}

export const Suppliers = {
  Coverage,
  Disabled,
  Warehouses,

  create: (supplier: Omit<SupplierBody, 'id'>): ApiRequestDescriptor<SupplierJSON> =>
    authenticate(post('/api/v1/suppliers', serialize(supplier))),

  index: (filters: SuppliersIndexFilters): ApiRequestDescriptor<SupplierSummaryJSON[]> =>
    authenticate(get('/api/v1/suppliers', filters)),

  show: (id: number): ApiRequestDescriptor<SupplierJSON> =>
    authenticate(get(`/api/v1/suppliers/${id}`)),

  update: (supplier: SupplierBody): ApiRequestDescriptor<SupplierJSON> =>
    authenticate(withSessionId(put(`/api/v1/suppliers/${supplier.id}`, serialize(supplier)))),

  destroy: (id: number): ApiRequestDescriptor<void> =>
    authenticate(withSessionId(del(`/api/v1/suppliers/${id}`))),
}
