import type { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { FormLayout, PhoneInput, Select } from '@advitam/ui';
import type { SelectableItem } from '@advitam/ui/components/Form/UI/Select';
import { ContactMedia } from '@advitam/api/models/Entity/ContactMedia';

import Layout from '../../Layout';
import { getFieldName } from '../utils';
import EmailFields from './EmailFields';
import LocationField from './LocationField';
import PhoneFields from './PhoneFields';
import messages from './messages';

interface ContactProps {
  contactPrefix?: string;
  addressPrefix?: string;
  withoutAuthenticDocument?: boolean;
  withoutCity?: boolean;
  withoutPreferredContact?: boolean;
  children?: ReactNode;
}

export default function Contact({
  contactPrefix = '',
  addressPrefix = '',
  withoutAuthenticDocument,
  withoutCity,
  withoutPreferredContact,
  children,
}: ContactProps): JSX.Element {
  const intl = useIntl();

  const preferredContactOptions: SelectableItem<ContactMedia>[] = [
    {
      name: intl.formatMessage(messages.email),
      value: ContactMedia.EMAIL,
    },
    {
      name: intl.formatMessage(messages.fax),
      value: ContactMedia.FAX,
    },
  ];

  const authenticDocumentOptions: SelectableItem[] = [
    {
      name: intl.formatMessage(messages.requiredDocumentsYes),
      value: true,
    },
    {
      name: intl.formatMessage(messages.requiredDocumentsNo),
      value: false,
    },
  ];

  return (
    <Layout.Fieldset title={<FormattedMessage id={messages.title.id} />}>
      <LocationField
        required
        prefix={addressPrefix}
        withoutCity={withoutCity}
      />
      <PhoneFields prefix={contactPrefix} />
      <FormLayout.Row>
        <PhoneInput
          label={<FormattedMessage id={messages.fax.id} />}
          name={getFieldName(contactPrefix, 'fax')}
        />
      </FormLayout.Row>
      <EmailFields prefix={contactPrefix} />
      {!withoutPreferredContact && (
        <FormLayout.Row>
          <Select
            label={<FormattedMessage id={messages.preferedContact.id} />}
            tooltip={
              <FormattedMessage id={messages.preferredContactTooltip.id} />
            }
            name={getFieldName(contactPrefix, 'prefered_contact_media')}
            items={preferredContactOptions}
          />
        </FormLayout.Row>
      )}
      {!withoutAuthenticDocument && (
        <FormLayout.Row>
          <Select
            unselectable
            label={<FormattedMessage id={messages.requiredDocuments.id} />}
            tooltip={
              <FormattedMessage id={messages.requiredDocumentsTooltip.id} />
            }
            name={getFieldName(contactPrefix, 'authentic_document_needed')}
            items={authenticDocumentOptions}
          />
        </FormLayout.Row>
      )}
      {children}
    </Layout.Fieldset>
  );
}
