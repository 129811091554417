import { Model } from '../Model'
import { EntityInvoicePrestation, EntityInvoicePrestationJSON } from './Prestation'
import type { EntityInvoiceEntityJSON } from './Entity'
import type { EntityInvoiceGroupJSON } from './Group'
import { EntityInvoiceDealJSON } from './Deal'

interface EntityInvoiceBase {
  id: number
  group: EntityInvoiceGroupJSON
  entity: EntityInvoiceEntityJSON
  invoice_id: string | null
  bank_transfer_label: string | null
  cost_tax_excl: number | null
  cost_tax_incl: number | null
  file: string | null
  deal: EntityInvoiceDealJSON | null
}

export interface EntityInvoiceJSON extends EntityInvoiceBase {
  paid_on: string | null
  prestations: EntityInvoicePrestationJSON[]
}

export interface EntityInvoice extends EntityInvoiceBase {
  paid_on: Date | null
  prestations: EntityInvoicePrestation[]
}

export class EntityInvoice extends Model<EntityInvoiceJSON> {
  constructor(data: EntityInvoiceJSON) {
    super(data)
    this.prestations = data.prestations.map(prestation => new EntityInvoicePrestation(prestation))
    this.paid_on = Model.parseDate(data.paid_on)
  }

  get finalCostTaxExcl(): number {
    return (
      this.cost_tax_excl ??
      this.prestations.reduce((acc, prestation) => acc + prestation.cost_ht, 0)
    )
  }

  get finalCostTaxIncl(): number {
    return (
      this.cost_tax_incl ??
      this.prestations.reduce((acc, prestation) => acc + prestation.cost_ttc, 0)
    )
  }
}
