import type { MinimalClientJSON } from '../../Client/Minimal'
import type { MinimalDefunctJSON } from '../../Defunct/Minimal'
import { Model } from '../../Model'
import { PaymentReceived } from '../../Payment/Received'

interface EntityInvoiceDealBase {
  id: number
  uuid: string
  invoice_business_id: string
  defunct: MinimalDefunctJSON | null
  owner: MinimalClientJSON
  payment_received: PaymentReceived[]
}

export interface EntityInvoiceDealJSON extends EntityInvoiceDealBase {
  ceremony_dt: string
}

export interface EntityInvoiceDeal extends EntityInvoiceDealBase {
  ceremony_dt: Date
}

export class EntityInvoiceDeal extends Model<EntityInvoiceDealJSON> {
  constructor(data: EntityInvoiceDealJSON) {
    super(data)
    this.ceremony_dt = Model.parseDate(data.ceremony_dt)
  }
}
