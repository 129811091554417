import { createSelector } from 'reselect';

import { makeSelectDealByType } from 'containers/Deal/selectors';

export const selectDealMarbleDomain = state => state.dealMarble;

export const makeSelectLoading = () =>
  createSelector(selectDealMarbleDomain, substate =>
    substate ? substate.loading : null,
  );

export const makeSelectError = () =>
  createSelector(selectDealMarbleDomain, substate =>
    substate ? substate.error : null,
  );

export const makeSelectGraveyard = () =>
  createSelector(makeSelectDealByType(), substate =>
    substate ? substate.graveyard : null,
  );

export const makeSelectGraveyardLocation = () =>
  createSelector(makeSelectGraveyard(), substate =>
    substate ? substate.location : null,
  );

export const makeSelectGraveyardLocationAddress = () =>
  createSelector(makeSelectGraveyardLocation(), substate =>
    substate ? substate.address : null,
  );

export const makeSelectPostalCodes = () =>
  createSelector(makeSelectGraveyardLocationAddress(), substate =>
    substate ? { marble: [substate.postal_code] } : null,
  );
