import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';

import { EmailInput, FormLayout, TextArea } from '@advitam/ui';
import { AdministrationActions, Fields, Layout } from 'containers/Crud';
import { assert } from 'lib/support';

import {
  makeSelectDestroyError,
  makeSelectIsDestroying,
  makeSelectRawWarehouse,
} from '../selectors';
import {
  destroyWarehouse,
  saveWarehouse,
  setIsWarehouseDisabled,
} from '../thunk';
import type { SupplierWarehouseForm } from './types';
import Wrapper from './Wrapper';
import messages from '../messages';

const FIELDS_PREFIX = 'warehouse';

export default function General(): JSX.Element {
  const dispatch = useDispatch();

  const isDestroying = useSelector(makeSelectIsDestroying());
  const destroyError = useSelector(makeSelectDestroyError());
  const warehouse = useSelector(makeSelectRawWarehouse());
  assert(warehouse !== null);

  const onSubmit = useCallback(
    (values: SupplierWarehouseForm) => {
      dispatch(saveWarehouse(values.warehouse));
    },
    [dispatch],
  );

  const onDelete = useCallback(() => {
    dispatch(destroyWarehouse());
  }, [dispatch]);

  const setIsDisabled = useCallback(
    (value: boolean) => {
      dispatch(setIsWarehouseDisabled(value));
    },
    [dispatch],
  );

  return (
    <Wrapper onSubmit={onSubmit}>
      <Layout.Container>
        {warehouse.id && (
          <AdministrationActions
            isDisabled={warehouse.disabled}
            setIsDisabled={setIsDisabled}
            entityName={warehouse.name}
            onDelete={onDelete}
            isDeletionLoading={isDestroying}
            destroyError={destroyError}
          />
        )}
        <Layout.ColumnGroup>
          <Layout.Column>
            <Layout.Fieldset
              title={<FormattedMessage id={messages.commentary.id} />}
            >
              <TextArea name={`${FIELDS_PREFIX}.comment`} />
            </Layout.Fieldset>
          </Layout.Column>
        </Layout.ColumnGroup>
        <Layout.FieldsetGrid>
          <Fields.Contact
            addressPrefix={FIELDS_PREFIX}
            contactPrefix={FIELDS_PREFIX}
            withoutAuthenticDocument
            withoutPreferredContact
          >
            <FormLayout.Row>
              <EmailInput
                name={`${FIELDS_PREFIX}.email_feedback`}
                label={<FormattedMessage id={messages.emailFeedback.id} />}
              />
            </FormLayout.Row>
          </Fields.Contact>
        </Layout.FieldsetGrid>
      </Layout.Container>
    </Wrapper>
  );
}
