import { useLayoutEffect } from 'react'

type Effect = Parameters<typeof useLayoutEffect>[0]
type Dependencies = Parameters<typeof useLayoutEffect>[1]

/**
 * This hook is a wrapper around useLayoutEffect avoiding the warning on server side
 *
 * @param effect The effect to run.
 * @param deps The dependencies of the effect.
 */
export function useClientLayoutEffect(effect: Effect, deps: Dependencies): void {
  if (typeof window === 'undefined') {
    return
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(effect, deps)
}
