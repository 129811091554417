import { isValidPhoneNumber } from 'react-phone-number-input';
import { safeFormatMessage } from './functions.typed.ts';

/**
 * Generates an anchor id from the object refered to.
 *
 * @param      {string}  type    The type of the object to create an anchor for.
 * @param      {object}  object  The object in question
 * @return     {string}  The anchor string generated.
 */
export function genAnchor(type, object) {
  if (type === 'step') {
    return `${object.eventType}-${object.id}`;
  }
  return '';
}

/**
 * Get the current year and month
 *
 * @return     {string}  The string with the format yyyy-mm
 */
export function getCurrentYearAndMonth() {
  const dateObj = new Date();
  const month = `${dateObj.getUTCMonth() + 1}`.padStart(2, 0);
  const year = dateObj.getUTCFullYear();
  return `${year}-${month}`;
}

/**
 * Check if email is valid (https://stackoverflow.com/a/46181/8392424)
 * @param {string} email String containing the email
 */
export function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
  return re.test(String(email).toLowerCase());
}

/**
 * Convert 1 to 01
 *
 * @param      {number}   s    The number we want to convert
 * @return     {string}  The converted value
 */
export function pad(s) {
  return s < 10 ? `0${s}` : s;
}

/**
 * Get the lowest value in array with a specefic selector
 *
 * @param      {array}   arr    The array of objects
 * @param      {string}  selector  The selector for objects
 * @return     {number}  The lowest value of the selector in objects
 */
export function minInArrayOfObj(arr, selector) {
  let min = arr[0][selector];
  for (let i = 0; i < arr.length; i += 1) {
    const value = arr[i][selector];
    min = value < min ? value : min;
  }
  return min;
}

/**
 * Get the hashCode of string
 *
 * @param     str         The string
 * @returns   {number}    The hashCode
 */
export function hashCode(str) {
  return Array.from(str).reduce(
    (s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, // eslint-disable-line no-bitwise
    0,
  );
}

/**
 *
 * @param     {number}    i   The number to transform
 * @returns   {string}    The color
 */
export function intToRGB(i) {
  const c = (i & 0x00ffffff).toString(16).toUpperCase(); // eslint-disable-line no-bitwise

  return '00000'.substring(0, 6 - c.length) + c;
}

/**
 * Transform a string in color
 *
 * @param     {string}  str The string to transform
 * @returns   {string}  The color
 */
export function strToRGB(str) {
  return `#${intToRGB(hashCode(str))}`;
}

/**
 * Escape meta characters in string for regex
 *
 * @param string
 * @returns a string with all meta characters escaped.
 */
export function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
}

/**
 * Return the new index for create a new object in array.
 * Determine the negative number to return.
 *
 * @param array of values
 * @param id name of id key
 * @returns {number} new index
 */
export function determineIdForNewObject(array, id) {
  let min = 0;
  array.forEach(elem => {
    if (elem[id] < min) min = elem[id];
  });
  return min - 1;
}

/**
 * Return a new object without few keys.
 *
 * @param object The object to filter
 * @param arrayKeys An array which contains the keys to remove.
 * @return object The object filtered
 */
export function objectFiltered(object, arrayKeys) {
  const nextObject = { ...object };
  arrayKeys.forEach(key => {
    delete nextObject[key];
  });
  return nextObject;
}

/**
 * Get the last item in a array
 *
 * @param   {Array}     array   The array
 * @returns {null|*}    * the last value
 */
export function getLastItemInArray(array) {
  if (array.length <= 0) return null;
  return array[array.length - 1];
}

/**
 * Sort an array which contains only string by alphabetical order
 *
 * @param   array   the array to sorted
 * @param   key     key to use in case of array of objects
 * @returns {*}   The array sorted
 */
export function arraySortedByAlphabeticalOrder(array, key = null) {
  if (key)
    return array.sort((firstElement, secondElement) =>
      firstElement[key].localeCompare(secondElement[key]),
    );
  return array.sort((firstElement, secondElement) =>
    firstElement.localeCompare(secondElement),
  );
}

/**
 * Sort items of array by key alphabetical order
 *
 * @param {array} array the array to sort
 * @param {string} key key of item
 * @param {boolean} sortDir sort direction (false === asc)
 * @returns {array} The array sorted
 */
export function itemsOfArraySortedByKeyAlphabeticalOrder(
  array,
  key,
  sortDir = false,
) {
  if (!sortDir) {
    return array.sort((firstElement, secondElement) =>
      firstElement[key].localeCompare(secondElement[key]),
    );
  }
  return array.sort((firstElement, secondElement) =>
    secondElement[key].localeCompare(firstElement[key]),
  );
}

/**
 * Function to sort items of array
 *
 * @param {array} arrayToSort array to sort
 * @param {string} keyToSort key of item to sort
 * @param {boolean} sortDir is it ascending or not (false === asc)
 */
export function sortKeyIntegerOfArray(arrayToSort, keyToSort, sortDir) {
  if (!sortDir) return arrayToSort.sort((a, b) => a[keyToSort] - b[keyToSort]);
  return arrayToSort.sort((a, b) => b[keyToSort] - a[keyToSort]);
}

/**
 * Check if the new value is not an empty string
 *
 * @param     {String}      description     The string to verify
 * @returns   {Boolean}
 */
export function isEmpty(description) {
  return description.trim() === '';
}

/**
 * Get funeral member
 *
 * @param   {Array}   funeralTeam   array of objects which contains all funeral team
 * @param   {Number}  id            id of user searched
 * @return  {{ email: string }}  member of funeralTeam
 */
export function getFuneralMember(funeralTeam, id) {
  return funeralTeam.find(member => member.id === id);
}

/**
 * Function to check if phone numbers are valid or not
 *
 * @param {Object} obj usually the substate in a selector
 * @returns {Boolean}
 */
export function arePhoneNumbersValid(obj) {
  if (!obj) return true;
  let isFaxValid = true;
  let isPhoneValid = true;
  let isPhone2Valid = true;
  let isPhone3Valid = true;

  if (obj.fax) isFaxValid = isValidPhoneNumber(obj.fax);
  if (obj.phone) isPhoneValid = isValidPhoneNumber(obj.phone);
  if (obj.phone_2) isPhone2Valid = isValidPhoneNumber(obj.phone_2);
  if (obj.phone_3) isPhone3Valid = isValidPhoneNumber(obj.phone_3);

  return isFaxValid && isPhoneValid && isPhone2Valid && isPhone3Valid;
}

/**
 * Parse a price
 *
 * This parses a price, rouding it to 2 digits after the comma
 *
 * @returns {Number} The parsed price
 */
export function parsePrice(value) {
  if (value === null) return 0;
  return Number(parseFloat(value).toFixed(2));
}

//
// Backward compatibility section
//
export function safeTranslation(messages, message, intl) {
  return safeFormatMessage(intl, messages, message);
}

export {
  formatApiMessage,
  getCookie,
  getUuid,
  toCamelCase as snakeCaseToCamelCase,
} from './functions.typed.ts';
