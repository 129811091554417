import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import type { EntityInvoiceJSON } from '@advitam/api/models/EntityInvoice';
import type { EntityInvoicePrestationJSON } from '@advitam/api/models/EntityInvoice/Prestation';

import { ApiError } from '@advitam/api';
import { SerializedApiError } from 'api';
import { BILLING_EDIT_MODAL } from './constants';
import { save, saveGroup, saveNewPrestation, savePrestation } from './thunk';

export interface State {
  isOpen: boolean;
  invoice: EntityInvoiceJSON | null;
  prestation: EntityInvoicePrestationJSON | null;
  isLoading: boolean;
  error: SerializedApiError | null;
}

export interface AppStateSubset {
  [BILLING_EDIT_MODAL]: State;
}

const initialState: State = {
  isOpen: false,
  invoice: null,
  prestation: null,
  isLoading: false,
  error: null,
};

interface EditPrestationPayload {
  invoice: EntityInvoiceJSON;
  prestation: EntityInvoicePrestationJSON;
}

const slice = createSlice({
  name: BILLING_EDIT_MODAL,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    createPrestation: state => {
      state.isOpen = true;
      state.invoice = null;
      state.prestation = null;
    },
    editPrestation: (
      state,
      { payload }: PayloadAction<EditPrestationPayload>,
    ) => {
      state.isOpen = true;
      state.invoice = payload.invoice;
      state.prestation = payload.prestation;
    },
    editGroup: (state, { payload }: PayloadAction<EntityInvoiceJSON>) => {
      state.isOpen = true;
      state.invoice = payload;
      state.prestation = null;
    },
    close: state => {
      state.isOpen = false;
      state.invoice = null;
      state.prestation = null;
      state.error = null;
    },
  },
  extraReducers: builder => {
    builder.addCase(save.pending, state => {
      state.isLoading = true;
      state.error = null;
    });
    builder.addCase(saveNewPrestation.fulfilled, state => {
      state.isLoading = false;
      state.isOpen = false;
    });
    builder.addCase(savePrestation.fulfilled, state => {
      state.isLoading = false;
      state.isOpen = false;
    });
    builder.addCase(saveGroup.fulfilled, state => {
      state.isLoading = false;
      state.isOpen = false;
    });
    builder.addCase(saveNewPrestation.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
    builder.addCase(savePrestation.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
    builder.addCase(saveGroup.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });
    /* eslint-enable no-param-reassign */
  },
});

export const {
  createPrestation,
  editPrestation,
  editGroup,
  close,
} = slice.actions;
export default slice;
