import { BillingType } from '../BillingType'
import { CheckoutType } from '../CheckoutType'
import { Country } from '../Country'
import { Model } from '../Model'
import { SupplierDependencyJSON } from './Dependency'

const enum PrestationType {
  TRANSPORT = 'transport',
  MARBLE = 'marble',
  PAPERWORKS = 'paperworks',
  COFFINS = 'coffins',
  ENGRAVING = 'engraving',
  EMBALMING = 'embalming',
  FLIGHT = 'flight',
  FLOWERS = 'flowers',
  TOMBSTONE = 'tombstone',
  PRESS = 'press',
  TRANSLATION = 'translation',
  CEREMONY = 'ceremony',
}

export const enum InsolvencyProceedingsStatus {
  SAFEGUARD = 'safeguard',
  REORGANIZATION = 'reorganization',
  LIQUIDATION = 'liquidation',
}

interface SupplierBase {
  id: number
  name: string
  address: string | null
  address_l2: string | null
  postal_code: string | null
  insee_code: string | null
  city: string | null
  department: string | null
  country: Country | null
  latitude: number | null
  longitude: number | null
  manual_address: boolean
  phone: string | null
  phone_2: string | null
  phone_3: string | null
  fax: string | null
  email: string | null
  email_2: string | null
  email_3: string | null
  email_4: string | null
  comment: string | null
  billing_type: BillingType
  checkout_type: CheckoutType | null
  cheque_order: string | null
  cheque_address: string | null
  rib: string | null
  iban: string | null
  siret: string | null
  vat: string | null
  short_name: string | null
  website: string | null
  headquarter_name: string | null
  headquarter_address: string | null
  headquarter_postal_code: string | null
  headquarter_country: Country | null
  headquarter_city: string | null
  activity_ceased: boolean
  insolvency_proceedings_status: InsolvencyProceedingsStatus | null
  last_known_revenue_year: number | null
  last_known_revenue_amount: number | null
  prestation_types: PrestationType[]
  suppliers_dependencies: SupplierDependencyJSON[]
  disabled: boolean
  negociated: boolean
  current_update_user_id: number | null
  last_update_user_id: number | null
}

export interface SupplierJSON extends SupplierBase {
  current_update_dt: string | null
  last_update_dt: string | null
  activity_ceased_date: string | null
  insolvency_proceedings_date: string | null
}

export interface Supplier extends SupplierBase {
  current_update_dt: Date | null
  last_update_dt: Date | null
  activity_ceased_date: Date | null
  insolvency_proceedings_date: Date | null
}

export class Supplier extends Model<SupplierJSON> {
  constructor(data: SupplierJSON) {
    super(data)
    this.current_update_dt = Model.parseDate(data.current_update_dt)
    this.last_update_dt = Model.parseDate(data.last_update_dt)
    this.activity_ceased_date = Model.parseDate(data.activity_ceased_date)
    this.insolvency_proceedings_date = Model.parseDate(data.insolvency_proceedings_date)
  }
}

export { PrestationType }
export type { SupplierDependencyJSON }
export type { SupplierCoverageJSON } from './Coverage'
