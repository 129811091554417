import {
  InsolvencyProceedingsStatus,
  Supplier,
} from '@advitam/api/models/Supplier';

import messages from '../../messages';
import style from '../../Supplier.module.scss';

export function getStatusMessageId(supplier: Supplier): string | null {
  if (supplier.activity_ceased) {
    return messages.activityCeased.id;
  }

  switch (supplier.insolvency_proceedings_status) {
    case InsolvencyProceedingsStatus.SAFEGUARD:
      return messages.insolvencyProceedingsStatusSafeguard.id;
    case InsolvencyProceedingsStatus.REORGANIZATION:
      return messages.insolvencyProceedingsStatusReorganization.id;
    case InsolvencyProceedingsStatus.LIQUIDATION:
      return messages.insolvencyProceedingsStatusLiquidation.id;
    default:
      return null;
  }
}

export function getTooltipDate(supplier: Supplier): Date | null {
  if (supplier.activity_ceased) {
    return supplier.activity_ceased_date;
  }

  if (supplier.insolvency_proceedings_status) {
    return supplier.insolvency_proceedings_date;
  }

  return null;
}

export function getStatusClassName(supplier: Supplier): string {
  const classes = [style.activity_status];
  if (supplier.activity_ceased) {
    classes.push(style.activity_ceased);
  } else if (supplier.insolvency_proceedings_status) {
    classes.push(style[supplier.insolvency_proceedings_status]);
  }

  return classes.join(' ');
}
