import { defineMessages } from 'react-intl';

export default defineMessages({
  resetPassword: { id: 'app.containers.Client.sections.General.resetPassword' },
  informations: { id: 'app.containers.Client.sections.General.informations' },
  civility: { id: 'app.containers.Client.sections.General.civility' },
  firstname: { id: 'app.containers.Client.sections.General.firstname' },
  lastname: { id: 'app.containers.Client.sections.General.lastname' },
  birthName: { id: 'app.containers.Client.sections.General.birthName' },
  birthDate: { id: 'app.containers.Client.sections.General.birthDate' },
  birthPlace: { id: 'app.containers.Client.sections.General.birthPlace' },
  nationality: { id: 'app.containers.Client.sections.General.nationality' },
  work: { id: 'app.containers.Client.sections.General.work' },
  deceased: { id: 'app.containers.Client.sections.General.deceased' },
  deceasedTooltip: {
    id: 'app.containers.Client.sections.General.deceasedTooltip',
  },
  deathDate: { id: 'app.containers.Client.sections.General.deathDate' },
  data: { id: 'app.containers.Client.sections.General.data' },
  optOut: { id: 'app.containers.Client.sections.General.optOut' },
  optOutTooltip: { id: 'app.containers.Client.sections.General.optOutTooltip' },
  optOutDate: { id: 'app.containers.Client.sections.General.optOutDate' },
  termsAcceptedAt: {
    id: 'app.containers.Client.sections.General.termsAcceptedAt',
  },
  lastLogin: { id: 'app.containers.Client.sections.General.lastLogin' },
  anonymized: { id: 'app.containers.Client.sections.General.anonymized' },
  anonymizedTooltip: {
    id: 'app.containers.Client.sections.General.anonymizedTooltip',
  },
  anonymizationDate: {
    id: 'app.containers.Client.sections.General.anonymizationDate',
  },
  contact: { id: 'app.containers.Client.sections.General.contact' },
  phone: { id: 'app.containers.Client.sections.General.phone' },
  mobilePhone: { id: 'app.containers.Client.sections.General.mobilePhone' },
  email: { id: 'app.containers.Client.sections.General.email' },
  companies: { id: 'app.containers.Client.sections.General.companies' },
  companiesTooltip: {
    id: 'app.containers.Client.sections.General.companiesTooltip',
  },
  advitam: { id: 'app.containers.Client.sections.General.advitam' },
  wtw: { id: 'app.containers.Client.sections.General.wtw' },
  filassistance: { id: 'app.containers.Client.sections.General.filassistance' },
});
