import { SuppliersIndexFilters } from '@advitam/api/v1/Suppliers';

export const SUPPLIERS = 'Suppliers';

export const PrestationType = {
  CEREMONY: 'ceremony',
  COFFINS: 'coffins',
  EMBALMING: 'embalming',
  ENGRAVING: 'engraving',
  FLIGHT: 'flight',
  FLOWERS: 'flowers',
  MARBLE: 'marble',
  PAPERWORKS: 'paperworks',
  PRESS: 'press',
  TOMBSTONE: 'tombstone',
  TRANSLATION: 'translation',
  TRANSPORT: 'transport',
} as const;

export const SUPPLIERS_PER_PAGE = 50;

export const DEFAULT_FILTERS: SuppliersIndexFilters = {
  per_page: SUPPLIERS_PER_PAGE,
  page: 1,
};
