import { ReactNode, useCallback, useMemo } from 'react';
import { useForm } from 'react-final-form';

import { useFormValues } from '@advitam/react';
import { FormUI } from '@advitam/ui';
import type { Address as AddressUI } from '@advitam/ui/components/Form/UI/GooglePlace/types';

import FlagSelect from './FlagSelect';

interface DefunctBirthPlaceInputProps {
  label: ReactNode;
  error?: boolean;
}

export default function DefunctBirthPlaceInput({
  label,
  error,
}: DefunctBirthPlaceInputProps): JSX.Element {
  const form = useForm();

  const values = useFormValues<string | null>([
    `deal.defunctInfo.person.birth_postal_code`,
    `deal.defunctInfo.person.birthLocation`,
    `deal.defunctInfo.person.birth_country.name`,
    `deal.defunctInfo.person.birth_country.code`,
  ]);

  const value = useMemo(
    (): AddressUI => ({
      street_name: null,
      street_number: null,
      postal_code: values[0],
      city: values[1],
      country: values[2],
      country_code: values[3],
      latitude: null,
      longitude: null,
    }),
    [values],
  );

  const onChange = useCallback(
    (r: AddressUI): void => {
      form.batch(() => {
        form.change('deal.defunctInfo.person.birth_postal_code', r.postal_code);
        form.change('deal.defunctInfo.person.birthLocation', r.city);
        form.change('deal.defunctInfo.person.birth_country.name', r.country);
        form.change(
          'deal.defunctInfo.person.birth_country.code',
          r.country_code,
        );
        form.change('deal.defunctInfo.person.birth_insee_code', null);
      });
    },
    [form],
  );

  return (
    <FormUI.GooglePlace
      label={label}
      value={value}
      onChange={onChange}
      error={error}
      onlyCities
      onlyFrance={false}
      prefix={<FlagSelect />}
    />
  );
}
