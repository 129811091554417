import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { AnyAction } from 'redux';

import { DealType } from '@advitam/api/models/Deal/Type';
import type { DefunctConcessionJSON } from '@advitam/api/models/Funnel/Marble/DefunctConcession';
import { StepType } from '@advitam/api/models/Step';
import { assert } from '@advitam/support';
import ManualMode from 'components/ManualMode/index.js';
import ConcessionSelection from 'components/ConcessionSelection/index.js';
import { getStepForConcession } from 'components/ConcessionSelection/utils.js';
import { getDefunctConcession } from 'components/ConcessionSelection/actions.js';
import {
  updateWishes,
  updateDeal,
} from 'containers/Deal/DealFuneral/actions.js';
import { makeSelectUser } from 'slices/auth';
import type { LegacyStep } from 'models/Deal/Step';
import { FuneralDetails } from 'models/Deal';
import { WANT_FAMILY_TO_BUY_CONCESSION } from 'utils/constants';

import OtherFuneralCompany from '../../DealFuneral/OtherFuneralCompany/index.js';
import {
  updateDealFromSteps,
  updateStep,
} from '../../DealFuneral/StepsSection/actions.js';
import { ProductsSection, StepsSection } from '../../DealFuneral/sections.js';
import { makeSelectStepDoneByOtherFuneral } from '../../DealFuneral/selectors.js';
import Commentaries from '../../Commentaries';
import {
  GROUP_FUNERAL,
  GROUP_URN,
  GROUP_FLOWERS,
  GROUP_MARBLE,
} from '../../constants';
import {
  makeSelectIsManualMode,
  makeSelectSteps,
  makeSelectWish,
  makeSelectWishes,
  makeSelectGraveyardConcessions,
  makeSelectGraveyardConcessionTypes,
} from '../../selectors.js';
import { makeSelectDeal } from '../../selectors.typed';
import { updateManualMode } from '../../actions.js';
import { makeSelectPostalCodes } from './selectors';
import style from './Steps.module.scss';

export default function Steps(): JSX.Element {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { dealUUID } = useParams();

  const deal = useSelector(makeSelectDeal());
  assert(deal !== null);
  const user = useSelector(makeSelectUser());
  assert(user !== null);
  const isManualMode = useSelector(makeSelectIsManualMode()) as boolean;
  const isStepDoneByOtherFuneral = useSelector(
    makeSelectStepDoneByOtherFuneral(),
  );
  const steps = useSelector(makeSelectSteps()) as LegacyStep[] | null;
  const wishes = useSelector(makeSelectWishes());
  const findWish = useSelector(makeSelectWish());
  const graveyardConcessions = useSelector(makeSelectGraveyardConcessions());
  const graveyardConcessionTypes = useSelector(
    makeSelectGraveyardConcessionTypes(),
  );
  const postalCodes = useSelector(makeSelectPostalCodes());

  const onUpdateManualMode = useCallback((value: boolean) => {
    dispatch(updateManualMode(value));
  }, []);
  const updateWish = useCallback(
    (wishType: unknown, value: unknown) => {
      dispatch(updateWishes(wishType, value, deal.funeral, DealType.FUNERAL));
    },
    [deal],
  );
  const onGetDefunctConcession = useCallback(
    (id: number) => {
      const stepToUpdate = steps && getStepForConcession(steps);

      const setDefunctConcession = (
        concession: DefunctConcessionJSON,
      ): AnyAction =>
        updateStep(
          steps,
          stepToUpdate.id,
          { ...stepToUpdate, concession: { ...concession, version_id: null } },
          (update: FuneralDetails) => {
            assert(deal.funeral !== undefined);
            dispatch(updateDeal(update, deal.funeral));
          },
        );
      dispatch(getDefunctConcession(id, setDefunctConcession));
    },
    [steps, deal],
  );

  const isNewDeal = !deal.id;
  const hasStepCremation = steps?.some(
    stepItem => stepItem.eventType === StepType.CREMATION,
  );
  const stepForConcession = steps && getStepForConcession(steps);
  const isFamilyBuyingConcession = findWish(WANT_FAMILY_TO_BUY_CONCESSION);

  return (
    <>
      {!deal.isFuneral && <Commentaries />}
      <div className={style.manual_mode}>
        <ManualMode
          isManualMode={isManualMode}
          onChangeManualMode={onUpdateManualMode}
          userRole={user.role}
        />
      </div>
      <StepsSection isNewDeal={isNewDeal} dealUUID={dealUUID} />
      {isStepDoneByOtherFuneral && <OtherFuneralCompany dealUUID={dealUUID} />}
      {!!stepForConcession && steps && (
        <ConcessionSelection
          location={stepForConcession.location || {}}
          concession={stepForConcession.concession}
          handleChange={(update: unknown): void => {
            dispatch(
              updateDealFromSteps(update, stepForConcession, steps, deal),
            );
          }}
          intl={intl}
          graveyardConcessionTypes={graveyardConcessionTypes}
          graveyardConcessions={graveyardConcessions}
          isUrn={hasStepCremation}
          isFamilyBuyingConcession={
            (isFamilyBuyingConcession && isFamilyBuyingConcession.active) ||
            false
          }
          getDefunctConcession={onGetDefunctConcession}
          wishes={wishes}
          findWish={findWish}
          updateWish={updateWish}
        />
      )}
      {!isNewDeal && (
        <div className="dealFuneral__space">
          <ProductsSection
            availableCategories={[
              GROUP_FUNERAL,
              GROUP_URN,
              GROUP_FLOWERS,
              GROUP_MARBLE,
            ]}
            isManualMode={isManualMode}
            postalCodes={postalCodes}
          />
        </div>
      )}
    </>
  );
}
