import { Objects } from '@advitam/support'
import { ClientJSON } from '../../models/Client'

import { FullClientJSON } from '../../models/Client/Full'

interface ClientUpdatePayload
  extends Pick<
    FullClientJSON,
    | 'civility'
    | 'firstname'
    | 'lastname'
    | 'birth_name'
    | 'birth_date'
    | 'birth_location'
    | 'birth_postal_code'
    | 'birth_insee_code'
    | 'nationality'
    | 'work'
    | 'phone'
    | 'phone_2'
    | 'phone_3'
    | 'email'
    | 'address'
    | 'address_l2'
    | 'postal_code'
    | 'insee_code'
    | 'city'
  > {
  country_code: string | null | undefined
  birth_country_code: string | null | undefined
}

export function serialize(
  client: Omit<Partial<FullClientJSON | ClientJSON>, 'id' | 'inserted_at' | 'updated_at'>,
): Partial<ClientUpdatePayload> {
  return {
    ...Objects.pick(
      client,
      'civility',
      'firstname',
      'lastname',
      'birth_name',
      'birth_date',
      'birth_location',
      'birth_postal_code',
      'birth_insee_code',
      'work',
      'phone',
      'phone_2',
      'phone_3',
      'email',
      'address',
      'address_l2',
      'postal_code',
      'insee_code',
      'city',
    ),
    nationality: (client as FullClientJSON).nationality,
    country_code: client.country === undefined ? undefined : client.country?.code || null,
    birth_country_code:
      client.birth_country === undefined ? undefined : client.birth_country?.code || null,
  }
}
