import { ReactNode, useCallback, useMemo, ReactElement } from 'react'
import { useForm } from 'react-final-form'

import Api from '@advitam/api'
import { getTokens } from '@advitam/api/lib/tokens'
import type { AutocompleteCityhallResult } from '@advitam/api/v1/Autocompletes'
import { useFormValues } from '@advitam/react'
import { FormUI } from '@advitam/ui'

import FlagSelect from './FlagSelect'
import style from './DefunctBrithPlaceInput.module.scss'
import { fieldName } from './utils'

interface AutocompleteProps {
  label: ReactNode
  prefix: string | null
  error?: ReactElement | boolean
  disabled?: boolean
}

export default function ApiBirthLocation({
  label,
  prefix,
  error,
  disabled,
}: AutocompleteProps): JSX.Element {
  const form = useForm()
  const values = useFormValues<string | null>([
    fieldName(prefix, 'birth_postal_code'),
    fieldName(prefix, 'birth_insee_code'),
    fieldName(prefix, 'birth_location'),
  ])
  const value = useMemo(
    () => ({
      id: 0,
      description: values[2] || '',
      name: values[2] || '',
      postal_code: values[0] || '',
      insee_code: values[1] || '',
    }),
    [values],
  )

  const onChange = useCallback(
    (r: AutocompleteCityhallResult | undefined): void => {
      form.batch(() => {
        form.change(fieldName(prefix, 'birth_location'), r?.name || null)
        form.change(fieldName(prefix, 'birth_postal_code'), r?.postal_code || null)
        form.change(fieldName(prefix, 'birth_insee_code'), r?.insee_code || null)
        form.change(fieldName(prefix, 'birth_country.code'), 'FR')
      })
    },
    [form, prefix],
  )

  return (
    <FormUI.Autosuggest
      className={style.input}
      label={label}
      endpoint={Api.V1.absolute(Api.V1.Autocompletes.Path.cityhalls)}
      value={value}
      requestHeaders={{ ...getTokens() }}
      onChange={onChange}
      error={error}
      prefix={<FlagSelect prefix={prefix} />}
      disabled={disabled}
    />
  )
}
