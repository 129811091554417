import { createSelector } from 'reselect';
import { isValidPhoneNumber } from 'react-phone-number-input';

import { validateEmail } from 'utils/functions';

import { makeSelectDealClients } from './Clients/selectors.ts';
import {
  makeSelectDeal,
  makeSelectRawDeal,
  makeSelectIsDirty as makeSelectDealIsDirty,
} from './selectors.typed.ts';

const selectDealTypeWrapperDomain = state => state.dealTypeWrapper;

export const makeSelectDealType = () =>
  createSelector(makeSelectDeal(), deal => (deal ? deal.deal_type : null));

const makeSelectLoading = () =>
  createSelector(selectDealTypeWrapperDomain, substate =>
    substate ? substate.loading : null,
  );

export const makeSelectLoadingStartTime = () =>
  createSelector(selectDealTypeWrapperDomain, substate =>
    substate ? substate.loadingStartTime : null,
  );

const makeSelectDealByType = () =>
  createSelector(makeSelectDeal(), deal =>
    deal ? deal[deal.deal_type] : null,
  );

const makeSelectCeremonyDT = () =>
  createSelector(makeSelectDealByType(), substate =>
    substate ? substate.ceremony_dt : null,
  );

const makeSelectProducts = () =>
  createSelector(makeSelectDealByType(), substate =>
    substate ? substate.products : [],
  );

const makeSelectItem = () =>
  createSelector(makeSelectDeal(), substate =>
    substate ? substate.item : null,
  );

const makeSelectProductsForItem = () =>
  createSelector(makeSelectItem(), substate =>
    substate ? substate.products : [],
  );

const makeSelectSteps = () =>
  createSelector(makeSelectDealByType(), substate =>
    substate ? substate.steps : null,
  );

export const makeSelectDealState = () =>
  createSelector(makeSelectDeal(), deal => deal.state);

const makeSelectError = () =>
  createSelector(selectDealTypeWrapperDomain, substate =>
    substate ? substate.error : null,
  );

const makeSelectManualSelection = () =>
  createSelector(makeSelectDeal(), substate =>
    substate ? substate.manualSelection : false,
  );

const makeSelectIsEmailValid = () =>
  createSelector(
    makeSelectDeal(),
    makeSelectDealClients(),
    (substate, clients) => {
      // Check clients email validity
      let isValid = true;
      clients.forEach(client => {
        // If there is an email and the email is not valid set isValid to false
        if (
          !client.isDestroyed &&
          client.contact &&
          client.contact.email &&
          !validateEmail(client.contact.email)
        ) {
          isValid = false;
        }
      });
      // Check person email validity (mother, father, partner)
      if (substate.defunctInfo && substate.defunctInfo.defunctCompInfo) {
        Object.keys(substate.defunctInfo.defunctCompInfo).forEach(person => {
          // If there is an email and the email is not valid set isValid to false
          if (
            substate.defunctInfo.defunctCompInfo[person] &&
            substate.defunctInfo.defunctCompInfo[person].contact &&
            substate.defunctInfo.defunctCompInfo[person].contact.email &&
            !validateEmail(
              substate.defunctInfo.defunctCompInfo[person].contact.email,
            )
          ) {
            isValid = false;
          }
        });
      }
      return isValid;
    },
  );

const makeSelectIsPhoneValid = () =>
  createSelector(
    makeSelectDeal(),
    makeSelectDealClients(),
    (substate, clients) => {
      let isDataValid = true;
      clients.forEach(client => {
        if (
          client.contact &&
          client.contact.phone &&
          !isValidPhoneNumber(client.contact.phone)
        )
          isDataValid = false;
        if (
          client.contact &&
          client.contact.phone_2 &&
          !isValidPhoneNumber(client.contact.phone_2)
        )
          isDataValid = false;
        if (
          client.contact &&
          client.contact.phone_3 &&
          !isValidPhoneNumber(client.contact.phone_3)
        )
          isDataValid = false;
      });

      if (!substate.otherFuneral || !substate.otherFuneral.contact)
        return isDataValid;

      if (
        substate.otherFuneral.contact.fax &&
        !isValidPhoneNumber(substate.otherFuneral.contact.fax)
      )
        isDataValid = false;
      if (
        substate.otherFuneral.contact.phone &&
        !isValidPhoneNumber(substate.otherFuneral.contact.phone)
      )
        isDataValid = false;
      if (
        substate.otherFuneral.contact.phone_2 &&
        !isValidPhoneNumber(substate.otherFuneral.contact.phone_2)
      )
        isDataValid = false;

      return isDataValid;
    },
  );

const makeSelectOrdersLinked = () =>
  createSelector(
    selectDealTypeWrapperDomain,
    substate => substate.ordersLinked,
  );

const makeSelectSuppliersAndBooking = () =>
  createSelector(selectDealTypeWrapperDomain, substate => {
    if (!substate || !substate.selectedSuppliers) {
      return {};
    }
    return substate.selectedSuppliers;
  });

const makeSelectSelectedSuppliers = () =>
  createSelector(makeSelectSuppliersAndBooking(), substate => {
    if (!substate || !substate.selection) {
      return {};
    }
    return substate.selection;
  });

const makeSelectBookingStatuses = () =>
  createSelector(makeSelectSuppliersAndBooking(), substate => {
    if (!substate || !substate.booking_statuses) {
      return {};
    }
    return substate.booking_statuses;
  });

const makeSelectLoadingSuppliers = () =>
  createSelector(selectDealTypeWrapperDomain, substate =>
    substate ? substate.loadingSuppliers : false,
  );

const makeSelectErrorModal = () =>
  createSelector(selectDealTypeWrapperDomain, substate => substate.errorModal);

const makeSelectSectionOpened = () =>
  createSelector(
    selectDealTypeWrapperDomain,
    substate => substate.sectionOpened,
  );

const makeSelectIsManualMode = () =>
  createSelector(
    selectDealTypeWrapperDomain,
    substate => substate.isManualMode,
  );

const makeSelectDefunctInfo = () =>
  createSelector(makeSelectDeal(), substate => substate.defunctInfo);

const makeSelectDefunctInfoPerson = () =>
  createSelector(makeSelectDefunctInfo(), substate => substate.person);

const makeSelectDefunctName = () =>
  createSelector(makeSelectDefunctInfoPerson(), substate => {
    if (!substate) return '';
    if (substate.lastName && substate.firstName)
      return `${substate.firstName} ${substate.lastName}`;
    return '';
  });

const makeSelectGraveyardConcessionTypes = () =>
  createSelector(
    selectDealTypeWrapperDomain,
    substate => substate.graveyardConcessionTypes,
  );

const makeSelectGraveyardConcessions = () =>
  createSelector(
    selectDealTypeWrapperDomain,
    substate => substate.graveyardConcessions,
  );

const makeSelectGraveyardBasicInfos = () =>
  createSelector(
    selectDealTypeWrapperDomain,
    substate => substate.graveyardBasicInfos,
  );

const makeSelectWishes = () =>
  createSelector(makeSelectDealByType(), substate =>
    substate ? substate.wishes : [],
  );

const makeSelectWish = () =>
  createSelector(makeSelectWishes(), substate => wishType =>
    substate.find(w => w.type === wishType),
  );

export const makeSelectTriggerPingServices = () =>
  createSelector(selectDealTypeWrapperDomain, state =>
    state.triggerPingServices.length > 0
      ? state.triggerPingServices
      : undefined,
  );

export {
  selectDealTypeWrapperDomain,
  makeSelectDeal,
  makeSelectRawDeal,
  makeSelectLoading,
  makeSelectDealIsDirty,
  makeSelectSteps,
  makeSelectError,
  makeSelectManualSelection,
  makeSelectIsEmailValid,
  makeSelectOrdersLinked,
  makeSelectSuppliersAndBooking,
  makeSelectSelectedSuppliers,
  makeSelectLoadingSuppliers,
  makeSelectBookingStatuses,
  makeSelectErrorModal,
  makeSelectSectionOpened,
  makeSelectIsManualMode,
  makeSelectDefunctInfo,
  makeSelectDefunctInfoPerson,
  makeSelectDefunctName,
  makeSelectProducts,
  makeSelectIsPhoneValid,
  makeSelectGraveyardConcessions,
  makeSelectGraveyardConcessionTypes,
  makeSelectGraveyardBasicInfos,
  makeSelectWish,
  makeSelectWishes,
  makeSelectProductsForItem,
  makeSelectItem,
  makeSelectDealByType,
  makeSelectCeremonyDT,
};
