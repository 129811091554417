import { useIntl } from 'react-intl';
import { Tooltip } from '@advitam/ui';
import Check from '@advitam/ui/images/icons/check.svg';
import messages from '../../messages';
import style from './State.module.scss';

interface StateProps {
  disabled: boolean;
  tooltip: boolean;
}

export default function State({
  disabled,
  tooltip: negociated,
}: StateProps): JSX.Element {
  const intl = useIntl();
  const classes = [style.container, disabled && style.disabled].filter(Boolean);

  const tooltipMessage = [
    intl.formatMessage(messages[disabled ? 'disabled' : 'active']),
    intl.formatMessage(messages[negociated ? 'negociated' : 'notNegociated']),
  ];

  return (
    <Tooltip
      content={tooltipMessage.join(', ')}
      contentClassName={classes.join(' ')}
    >
      {negociated && <Check />}
    </Tooltip>
  );
}
