import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button, Modal, ModalTitle } from '@advitam/ui';

import { FormattedMessage } from 'react-intl';
import {
  makeSelectMailContentModalRecord,
  makeSelectMailContentModalText,
} from './selectors';
import { closeMailContentModal } from './slice';
import { MODAL_TITLE } from './constants';
import messages from './messages';
import style from './History.module.scss';

export default function MailContentModal(): JSX.Element | null {
  const dispatch = useDispatch();

  const communication = useSelector(makeSelectMailContentModalRecord());
  const text = useSelector(makeSelectMailContentModalText());

  const onClose = useCallback(() => {
    dispatch(closeMailContentModal());
  }, [dispatch]);

  if (communication === null) {
    return null;
  }

  return (
    <Modal
      isOpen={communication !== null}
      onRequestClose={onClose}
      className={`new-design ${style.modal}`}
    >
      <ModalTitle>
        <FormattedMessage
          id={MODAL_TITLE[communication.channel_type] || messages.modalTitle.id}
        />
      </ModalTitle>
      <div className={`${style.modal_content} thin-scrollbar`}>
        <b>
          <FormattedMessage id={messages.emailSubject.id} />
        </b>
        <span>&nbsp;: {communication.subject}</span>
        <div
          className={`${style.modal_content_text}`}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: text || '' }}
        />
      </div>
      <div className={style.modal_buttons}>
        <Button
          fixedSize
          primary
          onClick={onClose}
          text={<FormattedMessage id={messages.closeModal.id} />}
        />
      </div>
    </Modal>
  );
}
