import type { ReactNode } from 'react';
import { FormattedMessage, FormattedDate } from 'react-intl';
import { useSelector } from 'react-redux';

import { assert } from '@advitam/support';
import { Tooltip, Text } from '@advitam/ui';

import { makeSelectSupplier } from '../../selectors';
import {
  getStatusClassName,
  getStatusMessageId,
  getTooltipDate,
} from './utils';

export default function Status(): ReactNode {
  const supplier = useSelector(makeSelectSupplier());
  assert(supplier !== null);

  const statusMessageId = getStatusMessageId(supplier);

  if (!statusMessageId) {
    return null;
  }

  const tooltipDate = getTooltipDate(supplier);
  const className = getStatusClassName(supplier);

  return (
    <div className={className}>
      <Tooltip
        disabled={!tooltipDate}
        content={
          tooltipDate && (
            <FormattedDate
              value={tooltipDate}
              year="numeric"
              month="long"
              day="numeric"
            />
          )
        }
      >
        <Text>
          <FormattedMessage id={statusMessageId} />
        </Text>
      </Tooltip>
    </div>
  );
}
