import { FormattedMessage } from 'react-intl';

import { useBreakpoint } from '@advitam/react';
import { ResourceList } from '@advitam/ui';

import messages from './messages';
import style from './Billing.module.scss';

export default function Header(): JSX.Element {
  const isAboveXXL = useBreakpoint(parseInt(style.xxl, 10));

  return (
    <ResourceList.Header className={style.header}>
      <ResourceList.Cell />
      {isAboveXXL && (
        <ResourceList.Cell>
          <FormattedMessage id={messages.date.id} />
        </ResourceList.Cell>
      )}
      <ResourceList.Cell>
        <FormattedMessage id={messages.entity.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.group.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.defunctOrClient.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.prestation.id} />
      </ResourceList.Cell>
      {isAboveXXL && <ResourceList.Cell>#</ResourceList.Cell>}
      <ResourceList.Cell>
        <FormattedMessage id={messages.priceTaxExcl.id} />
      </ResourceList.Cell>
      <ResourceList.Cell>
        <FormattedMessage id={messages.priceTaxIncl.id} />
      </ResourceList.Cell>
      <ResourceList.Cell hiddenOnTablet>
        <FormattedMessage id={messages.actions.id} />
      </ResourceList.Cell>
    </ResourceList.Header>
  );
}
