import { useMemo } from 'react';
import { FormattedDate } from 'react-intl';

import {
  EntityInvoice,
  EntityInvoiceJSON,
} from '@advitam/api/models/EntityInvoice';
import { EntityInvoiceGroupType } from '@advitam/api/models/EntityInvoice/Group/Type';
import { useBreakpoint } from '@advitam/react';
import { FormattedPrice, Person, ResourceList } from '@advitam/ui';

import InvoiceIcon from './InvoiceIcon';
import OpenDeal from './OpenDeal';
import ValidateIcon from './ValidateIcon';
import EditGroupIcon from './Edit/EditGroupIcon';
import EditPrestationIcon from './Edit/EditPrestationIcon';
import style from './List.module.scss';
import PaymentIcon from './PaymentIcon';
import PaymentReceivedIcon from './PaymentRecevedIcon';

interface RowProps {
  invoice: EntityInvoiceJSON;
}

export default function RowGroup({ invoice }: RowProps): JSX.Element | null {
  const invoiceInstance = useMemo(() => new EntityInvoice(invoice), [invoice]);

  const isAboveXXL = useBreakpoint(parseInt(style.xxl, 10));

  return (
    <>
      <ResourceList.Row className={style.group_head}>
        <ResourceList.Cell>
          <PaymentReceivedIcon
            deals={invoice.prestations.map(prestation => prestation.deal)}
          />
        </ResourceList.Cell>
        <ResourceList.Cell>
          <span className={style.group_title}>
            {invoice.entity.name} - {invoice.group.name}
          </span>
        </ResourceList.Cell>
        {isAboveXXL && <ResourceList.Cell />}
        <ResourceList.Cell hiddenOnTablet />
        <ResourceList.Cell hiddenOnMobile />
        <ResourceList.Cell hiddenOnMobile />
        {isAboveXXL && <ResourceList.Cell />}
        <ResourceList.Cell>
          <FormattedPrice value={invoiceInstance.finalCostTaxExcl} />
        </ResourceList.Cell>
        <ResourceList.Cell>
          <FormattedPrice value={invoiceInstance.finalCostTaxIncl} />
        </ResourceList.Cell>
        <ResourceList.Cell className={style.actions} hiddenOnTablet>
          {invoice.group.type === EntityInvoiceGroupType.DEAL && (
            <OpenDeal prestation={invoice.prestations[0]} />
          )}
          <InvoiceIcon invoice={invoice} />
          <ValidateIcon invoice={invoice} />
          <PaymentIcon invoice={invoiceInstance} />
          <EditGroupIcon invoice={invoice} />
        </ResourceList.Cell>
      </ResourceList.Row>

      {invoice.prestations.map((prestation, index) => {
        const person = prestation.deal.defunct || prestation.deal.owner;
        return (
          <ResourceList.Row
            key={prestation.id}
            className={[
              style.group_cell,
              index === invoice.prestations.length - 1 ? style.last : null,
            ]
              .filter(Boolean)
              .join(' ')}
          >
            <ResourceList.Cell>
              <PaymentReceivedIcon deals={[prestation.deal]} />
            </ResourceList.Cell>
            {isAboveXXL && (
              <ResourceList.Cell>
                <FormattedDate
                  value={invoiceInstance.prestations[index].deal.ceremony_dt}
                />
              </ResourceList.Cell>
            )}
            <ResourceList.Cell hiddenOnMobile />
            <ResourceList.Cell hiddenOnTablet />
            <ResourceList.Cell>
              {person && <Person.FormattedName person={person} />}
            </ResourceList.Cell>
            <ResourceList.Cell>{prestation.description}</ResourceList.Cell>
            {isAboveXXL && (
              <ResourceList.Cell>{prestation.amount}</ResourceList.Cell>
            )}
            <ResourceList.Cell>
              <FormattedPrice value={prestation.cost_ht} />
            </ResourceList.Cell>
            <ResourceList.Cell>
              <FormattedPrice value={prestation.cost_ttc} />
            </ResourceList.Cell>
            <ResourceList.Cell hiddenOnTablet>
              <div className={style.actions}>
                {invoice.group.type !== EntityInvoiceGroupType.DEAL && (
                  <OpenDeal prestation={prestation} />
                )}
                <EditPrestationIcon invoice={invoice} prestation={prestation} />
              </div>
            </ResourceList.Cell>
          </ResourceList.Row>
        );
      })}
    </>
  );
}
