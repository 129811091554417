import { defineMessages } from 'react-intl';

export default defineMessages({
  active: { id: 'app.containers.Suppliers.active' },
  ceremonyType: { id: 'app.containers.Suppliers.ceremonyType' },
  coffinsType: { id: 'app.containers.Suppliers.coffinsType' },
  createSupplier: { id: 'app.containers.Suppliers.createSupplier' },
  departmentFilter: { id: 'app.containers.Suppliers.departmentFilter' },
  disabled: { id: 'app.containers.Suppliers.disabled' },
  embalmingType: { id: 'app.containers.Suppliers.embalmingType' },
  engravingType: { id: 'app.containers.Suppliers.engravingType' },
  email: { id: 'app.containers.Suppliers.email' },
  flightType: { id: 'app.containers.Suppliers.flightType' },
  flowersType: { id: 'app.containers.Suppliers.flowersType' },
  marbleType: { id: 'app.containers.Suppliers.marbleType' },
  negociatedFilter: { id: 'app.containers.Suppliers.negociatedFilter' },
  paperworksType: { id: 'app.containers.Suppliers.paperworksType' },
  phone: { id: 'app.containers.Suppliers.phone' },
  pressType: { id: 'app.containers.Suppliers.pressType' },
  search: { id: 'app.containers.Suppliers.search' },
  status: { id: 'app.containers.Suppliers.status' },
  tombstoneType: { id: 'app.containers.Suppliers.tombstoneType' },
  translationType: { id: 'app.containers.Suppliers.translationType' },
  transportType: { id: 'app.containers.Suppliers.transportType' },
  typeFilter: { id: 'app.containers.Suppliers.typeFilter' },
  negociated: { id: 'app.containers.Suppliers.negociated' },
  notNegociated: { id: 'app.containers.Suppliers.notNegociated' },
});
