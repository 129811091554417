import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  activityCeased: { id: 'app.containers.Supplier.activityCeased' },
  commentary: { id: 'app.containers.Supplier.commentary' },
  general: { id: 'app.containers.Supplier.general' },
  insolvencyProceedingsStatusLiquidation: {
    id: 'app.containers.Supplier.insolvencyProceedingsStatusLiquidation',
  },
  insolvencyProceedingsStatusReorganization: {
    id: 'app.containers.Supplier.insolvencyProceedingsStatusReorganization',
  },
  insolvencyProceedingsStatusSafeguard: {
    id: 'app.containers.Supplier.insolvencyProceedingsStatusSafeguard',
  },
  miscellaneous: { id: 'app.containers.Supplier.miscellaneous' },
  negociated: { id: 'app.containers.Supplier.negociated' },
  negociatedTooltip: { id: 'app.containers.Supplier.negociatedTooltip' },
  titlePlaceholder: { id: 'app.containers.Supplier.titlePlaceholder' },
  warehouses: { id: 'app.containers.Supplier.warehouses' },
});
