import { FormattedMessage } from 'react-intl';

import type {
  EntityInvoiceDeal,
  EntityInvoiceDealJSON,
} from '@advitam/api/models/EntityInvoice/Deal';
import { PaymentReceived } from '@advitam/api/models/Payment/Received';
import { Tooltip } from '@advitam/ui';
import Warning from '@advitam/ui/images/icons/warning.svg';

import messages from './messages';
import style from './List.module.scss';

interface PaymentReceivedIconProps {
  deals: Array<EntityInvoiceDeal | EntityInvoiceDealJSON>;
}

export default function PaymentReceivedIcon({
  deals,
}: PaymentReceivedIconProps): JSX.Element | null {
  const isLost = deals.some(deal =>
    deal.payment_received.includes(PaymentReceived.LOST),
  );

  if (isLost) {
    return (
      <Tooltip content={<FormattedMessage id={messages.paymentLost.id} />}>
        <Warning className={style.lost} />
      </Tooltip>
    );
  }

  const isNotReceived = deals.some(deal =>
    deal.payment_received.includes(PaymentReceived.NO),
  );
  if (isNotReceived) {
    return (
      <Tooltip
        content={<FormattedMessage id={messages.paymentNotReceived.id} />}
      >
        <Warning className={style.not_received} />
      </Tooltip>
    );
  }

  return null;
}
