import { useCallback, useState, MouseEvent } from 'react'
import { useForm } from 'react-final-form'

import { useFormValue } from '@advitam/react'
import { Popup, TransparentButton } from '@advitam/ui'
import ChevronDown from '@advitam/ui/images/icons/chevron-down.svg'

import OtherFlag from './other.svg'
import FranceFlag from './france.svg'
import style from './DefunctBrithPlaceInput.module.scss'
import { fieldName } from './utils'

interface FlagSelectProps {
  prefix: string | null
}

export default function FlagSelect({ prefix }: FlagSelectProps): JSX.Element {
  const form = useForm()
  const country = useFormValue<string | null>(fieldName(prefix, 'birth_country.code'))
  const isFrance = !country || country === 'FR'

  const [isDropdownOpen, setDropdownOpen] = useState(false)

  const openDropdown = useCallback(
    (event: MouseEvent): void => {
      event.stopPropagation()
      event.preventDefault()
      setDropdownOpen(true)
    },
    [setDropdownOpen],
  )
  const closeDropdown = useCallback((): void => {
    setDropdownOpen(false)
  }, [setDropdownOpen])

  const selectCountry = useCallback(
    (code: string): void => {
      form.batch(() => {
        form.change(fieldName(prefix, 'birth_country.code'), code)
        form.change(fieldName(prefix, 'birth_country.name'), '')
        form.change(fieldName(prefix, 'birth_location'), '')
        form.change(fieldName(prefix, 'birth_postal_code'), '')
        form.change(fieldName(prefix, 'birth_insee_code'), '')
      })
      closeDropdown()
    },
    [form, closeDropdown, prefix],
  )

  const selectFrance = useCallback(
    (event: MouseEvent): void => {
      event.stopPropagation()
      selectCountry('FR')
    },
    [selectCountry],
  )

  const selectOther = useCallback(
    (event: MouseEvent): void => {
      event.stopPropagation()
      selectCountry('XX')
    },
    [selectCountry],
  )

  return (
    <Popup className={style.popup} onFocusOut={closeDropdown} onClick={openDropdown} autoFocus>
      <div className={style.flag_container}>
        {isFrance ? <FranceFlag /> : <OtherFlag />}
        <ChevronDown className={style.chevron} />
      </div>
      {isDropdownOpen && (
        <div className={style.dropdown_content}>
          <TransparentButton onClick={selectFrance}>
            <FranceFlag />
          </TransparentButton>
          <TransparentButton onClick={selectOther}>
            <OtherFlag />
          </TransparentButton>
        </div>
      )}
    </Popup>
  )
}
