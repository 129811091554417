import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { withSlice } from '@advitam/react';
import Button from '@advitam/ui/components/Button';
import { Layout } from 'containers/Crud';

import Wrapper from '../Wrapper';
import Informations from './Informations';
import Data from './Data';
import Contact from './Contact';
import Companies from './Companies';
import messages from './messages';
import PasswordResetModal, {
  open as openPasswordModal,
  slice as passwordResetModalSlice,
} from './PasswordResetModal';
import DeceasedModal, { slice as deceasedModalSlice } from './DeceasedModal';
import { makeSelectInitialFormValues } from './selectors';
import type { GeneralForm } from './types';
import { saveGeneralSection } from './thunk';

function General(): JSX.Element {
  const dispatch = useDispatch();

  const initialValues = useSelector(makeSelectInitialFormValues());

  const onSubmit = useCallback(
    (values: GeneralForm) => {
      dispatch(saveGeneralSection(values));
    },
    [dispatch],
  );

  const onResetPassword = useCallback(() => {
    dispatch(openPasswordModal());
  }, [dispatch]);

  return (
    <Wrapper initialValues={initialValues} onSubmit={onSubmit}>
      <Layout.Container>
        <Layout.ColumnGroup>
          <div>
            <Button
              outline
              onClick={onResetPassword}
              text={<FormattedMessage id={messages.resetPassword.id} />}
              disabled={!initialValues.client.email}
            />
          </div>
        </Layout.ColumnGroup>
        <Layout.ColumnGroup>
          <Layout.Column>
            <Informations />
            <Data />
          </Layout.Column>
          <Layout.Column>
            <Contact />
            <Companies />
          </Layout.Column>
        </Layout.ColumnGroup>
      </Layout.Container>

      <DeceasedModal />
      <PasswordResetModal />
    </Wrapper>
  );
}

export default withSlice(deceasedModalSlice, passwordResetModalSlice)(General);
