import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ApiError, SerializedApiError } from '@advitam/api';
import type { ProductType } from '@advitam/api/models/Product';
import type { SupplierWarehouseProductJSON } from '@advitam/api/models/Supplier/Warehouse';
import { Arrays } from '@advitam/support';

import { SPECIFIC_FILTER_MAP, SUPPLIER_WAREHOUSE_PRODUCTS } from './constants';
import { fetchWarehouseProducts, saveProducts } from './thunk';
import type { ProductsFilters } from './types';

export interface State {
  products: SupplierWarehouseProductJSON[];
  filters: ProductsFilters;
  isLoading: boolean;
  error: SerializedApiError | null;
  isSaving: boolean;
}

export interface AppStateSubset {
  [SUPPLIER_WAREHOUSE_PRODUCTS]: State;
}

const initialState: State = {
  products: [],
  filters: {},
  isLoading: false,
  error: null,
  isSaving: false,
};

function getSpecificFilterKeys(
  category: ProductType | undefined,
): Array<keyof ProductsFilters> {
  if (!category) {
    return [];
  }
  return SPECIFIC_FILTER_MAP[category];
}

const slice = createSlice({
  name: SUPPLIER_WAREHOUSE_PRODUCTS,
  initialState,
  reducers: {
    /* eslint-disable no-param-reassign */
    setFilters: (state, { payload }: PayloadAction<ProductsFilters>) => {
      const previousSpecificFilters = getSpecificFilterKeys(
        state.filters.category_type_eq,
      );

      state.filters = payload;

      const newSpecificFilters = getSpecificFilterKeys(
        payload.category_type_eq,
      );
      const filtersToRemove = Arrays.difference(
        previousSpecificFilters,
        newSpecificFilters,
      );
      filtersToRemove.forEach(filter => {
        delete state.filters[filter];
      });
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchWarehouseProducts.pending, state => {
      state.isLoading = true;
      state.error = null;
      state.products = [];
    });
    builder.addCase(fetchWarehouseProducts.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.products = payload;
    });
    builder.addCase(fetchWarehouseProducts.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.error = ApiError.serialize(payload);
    });

    builder.addCase(saveProducts.pending, state => {
      state.isSaving = true;
    });
    builder.addCase(saveProducts.fulfilled, (state, { payload }) => {
      if (payload) {
        state.products = payload.products;
      }
      state.isSaving = false;
    });
    builder.addCase(saveProducts.rejected, (state, { payload }) => {
      state.isSaving = false;
      state.error = ApiError.serialize(payload);
    });

    /* eslint-enable no-param-reassign */
  },
});

export const { setFilters } = slice.actions;
export default slice;
