import { ReactNode, useCallback } from 'react'
import { Field, FieldRenderProps } from 'react-final-form'

import { useFormValue } from '@advitam/react'
import { HiddenInput } from '@advitam/ui'

import Api from './Api'
import Google from './Google'
import { fieldName } from './utils'
import style from './DefunctBrithPlaceInput.module.scss'

interface BirthPlaceInputProps {
  label: ReactNode
  prefix?: string | null
  disabled?: boolean
}

export default function BirthPlaceInput({
  label,
  prefix = null,
  disabled,
}: BirthPlaceInputProps): JSX.Element {
  const country = useFormValue<string | null>(fieldName(prefix, 'birth_country.code'))
  const isFrance = !country || country === 'FR'

  const render = useCallback(
    ({ meta }: FieldRenderProps<string>) =>
      isFrance ? (
        <Api
          label={label}
          prefix={prefix}
          disabled={disabled}
          error={meta.touched && !meta.valid}
        />
      ) : (
        <Google
          label={label}
          prefix={prefix}
          disabled={disabled}
          error={meta.touched && !meta.valid}
        />
      ),
    [isFrance, label, prefix, disabled],
  )

  return (
    <div className={style.container}>
      <Field name={fieldName(prefix, 'birth_location')} render={render} />
      <HiddenInput name={fieldName(prefix, 'birth_country.name')} />
      <HiddenInput name={fieldName(prefix, 'birth_country.code')} />
      <HiddenInput name={fieldName(prefix, 'birth_postal_code')} />
      <HiddenInput name={fieldName(prefix, 'birth_insee_code')} />
    </div>
  )
}
