import { ProductType } from '@advitam/api/models/Product';

import type { ProductsFilters, UnsavedSupplierWarehouseProduct } from './types';

export const SUPPLIER_WAREHOUSE_PRODUCTS = 'SupplierWarehouse/Products';

export const PRODUCT_SKELETON: UnsavedSupplierWarehouseProduct = {
  supplier_ref: null,
  price: null,
  cost: null,
  default: false,
  funnel_default: false,
  product: null,
  created_at: null,
  updated_at: null,
};

export const SPECIFIC_FILTER_MAP: Record<
  ProductType,
  Array<keyof ProductsFilters>
> = {
  [ProductType.COFFIN]: ['hermetic_eq', 'size_eq', 'is_default_eq'],
  [ProductType.MATTRESS]: ['color_eq', 'is_default_eq'],
  [ProductType.URN]: ['color_eq', 'is_default_eq'],
  [ProductType.PLATE]: ['color_eq', 'material_eq'],
  [ProductType.SEMELLE]: ['color_eq', 'material_eq', 'size_eq'],
  [ProductType.MONUMENT]: ['color_eq', 'material_eq', 'worship_tag_eq'],
  [ProductType.FLOWER]: ['color_eq', 'size_eq'],
  [ProductType.SUPPORT_PILLOW]: [],
  [ProductType.ACCESSORY]: [],
  [ProductType.ANNOUNCEMENT]: [],
  [ProductType.BONES_BOX]: [],
  [ProductType.LETTER_SENDING]: [],
  [ProductType.MEDALLION]: [],
  [ProductType.PATTERN]: [],
  [ProductType.PRESS]: [],
  [ProductType.REPAT_BODY_BAG]: [],
  [ProductType.STONES]: [],
  [ProductType.SYMBOL]: [],
  [ProductType.SYMBOL_GRAVEYARD]: [],
  [ProductType.TREE]: [],
};
